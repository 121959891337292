/* title */
.bold-infinite-title-section {
    display: flex;
    justify-content: space-between;
    padding: 6px 10px;
}
.bold-infinite-title-section h3 {
    font-weight: 300;
    color: #505050;
}

button.bold-infinite-option {
    font-size: 14px;
    background: transparent;
    border: 1px solid #c3c3c3;
    padding: 4px 10px;
    color: #828282;
    line-height: 14px;
    border-radius: 5px;
    height: 34px;
}

button.bold-infinite-option span {
    font-size: 14px;
}

/* items */
.bold-infinite-row {
    display: flex;
    align-items: center;
}

/* inputs */
label.bold-infinite-label {
    display: block;
    font-size: 12px;
    font-weight: 700;
    color: #828282;
}
.bold-box-input {
    margin: 0px 7px;
    flex: 50;
}

.bold-infinite-field{
    height: 30px;
    border: 1px solid #d9d9d9;
    width: 100%;
}