@import url(./../../assets/css-generated/variables.css);

.card-switch-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    border-radius: 10px;
    border: 1px solid var(--light-elements-elements-1);
    background: var(--light-background-background-1);
    margin-bottom: 5px;
    width: 100%;
}

.card-switch-body{
    display: flex;
    align-items: center;
    gap: 20px;
}
.card-switch-image{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    overflow: hidden;
    background-color: var(--light-primary-blue-1);
    color: var(--light-text-text-3);
}
.card-switch-image img{
    height: 100%;
    aspect-ratio: auto;
}
.card-switch-label{
    color: var(--light-text-text-1);
    font-weight: 600; 
}
.card-switch-inputContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-switch-input{
    display: none; 
}
.card-switch-input + .custom-checkbox{
    background-color: var(--light-text-text-7);
}
  
.card-switch-input + .custom-checkbox::before {
    content: "";
    width: 20px; 
    height: 20px; 
    background-color: var(--light-background-background-1); 
    border-radius: 50%; 
    position: absolute;
    top: 2.5px;
    left: 3px; 
    transition: left 0.2s; 
}

.card-switch-input:checked + .custom-checkbox::before {
    left: 22px; 
}

.card-switch-input:checked + .custom-checkbox {
    background-color: var(--light-primary-blue-2); 
}


/* dark mode */
.dark .card-switch-container{
    background: var(--dark-background-background-1);
}
.dark .card-label{
    color: var(--dark-text-text-1);
}
.dark .card-switch-input + .custom-checkbox{
    background-color: var(--dark-text-text-7);
}
.dark .card-switch-input:checked + .custom-checkbox {
    background-color: var(--dark-primary-blue-2); 
}
.dark .card-switch-input + .custom-checkbox::before {
    background-color: var(--dark-background-background-1); 
}
.dark .card-switch-container{
    border: 1px solid var(--dark-elements-elements-1);
    background: var(--dark-background-background-1);
}
.dark .card-switch-image{
    background-color: var(--dark-primary-blue-1);
    color: var(--dark-text-text-3);

}