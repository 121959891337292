.input-date {
    background-color: var(--light-background-background-1);
    overflow: 'hidden';
}

.dark .input-date {
    background-color: var(--dark-background-background-1);

}

.dark .input-date input::-webkit-calendar-picker-indicator {
    filter: invert(1); 
}

.dark .input-date input::-moz-calendar-picker-indicator {
    filter: invert(1);
}
