.cardAgenda {
    width: 100%;
    display: flex;
    align-items: center;
    border: 2px solid var(--dark-elements-border-panels-1); 
    border-left-width: 15px; 
    border-left-style: solid; 
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 80px;
}

.cardColorAgenda{
    background-color: var(--light-background-background-1);
    border-color: var(--light-services-blue-blue-1);
}

.dark .cardColorAgenda{
    background-color: var(--dark-background-background-1);
    border-color: var(--dark-elements-border-panels-1);

}

.AgendatContainerStyle {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.textCardTitleAgenda {
    margin: 0;
    color: var(--light-text-text-1);
    font-weight: 700;
    font-size: 14px;
}

.dark .textCardTitleAgenda {
    color: var(--dark-text-text-1)
}

.textCardSubtitleAgenda{
    font-size: 12px;
    font-weight: 600;
    color: var(--light-text-text-2);
    margin: 0; 
}

.dark .textCardSubtitleAgenda{
    color: var(--dark-text-text-2);
}

.labelStylesAgenda{
    background: var(--light-elements-elements-15);
    display: block;
    padding: 2px 10px;
    border-radius: 10px;
    margin: 0px 0px;
    color: var(--light-text-text-1);
    font-size: 11px;
    font-weight: 600;
}

.agendaStatusSpace{
    justify-content: space-between;
}

.statusAgenda{
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 25px;
    width: 100px;
    justify-content: center;
    background: var(--light-primary-yellow-1);
    font-weight: 700;
    margin: 4px 0px;
    padding: 8px 10px;
    border: 0px;
    border-radius: 10px;
}

.borderIconAgenda{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    width: 18px;
    border: 1px solid;
    border-radius: 25px;
    font-size: 12px;
}

.borderColorIconRed{
    border-color: var(--dark-status-error);
    background-color: var(--light-background-background-1);
}

.borderColorIconYellow{
    border-color: var(--light-primary-yellow-1);
    background-color: var(--light-background-background-1);
}

.borderColorIconGray{
    border-color: var(--light-neutral-gray-1);
    background-color: var(--light-background-background-1);
}

.label-new {
    background-color: var(--light-primary-yellow-4);
}

.label-frequent {
    background-color: var(--light-status-success-2);
}

.label-walking {
    background-color: var(--light-elements-elements-15);
}

.label-no-prefer {
    background-color: var(--light-background-background-2);
}

.label-VIP {
    background-color: var(--light-elements-elements-10);
    color: var(--light-primary-yellow-3);
}

.card-paid {
    background-color: var(--light-elements-elements-15);
}

.dark .card-paid {
    background-color: var(--dark-elements-elements-15);
}

.status-paid {
    background-color: var(--light-primary-yellow-5); 
}