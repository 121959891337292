.component-container {
    width: 100%;
    margin-bottom: 5px;
    position: relative;
}
.colorCardServices{
    background-color: var(--light-primary-blue-1);
    color: var(--light-text-text-1);
}
.selectedText{
    color: var(--light-text-text-3);
}
.carddropdown-title{
    flex: 5;
}
.carddropdown-card-fluid{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    border: 1px solid var(--light-neutral-gray-3);
    background-color: var(--light-background-background-1);
    border-radius: 10px;
    justify-content: space-between;
    transition: height 0.3s ease-out;
    margin: 0;
    position: relative;
    padding: 10px 15px;
}
.carddropdown-buttons-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex: 1;
    min-width: 140px;
}
.carddropdown-optionsgroup{
    width: 200px;
    position: absolute;
    background-color: var(--light-background-background-1);
}
.carddropdown button{
    border: none;
    background: none;
    
}
.carddropdown-item span{
    font-size: 14px;
}
.carddropdown-item:hover{
    background-color: var(--light-elements-elements-2);
    cursor: pointer;
}
.carddropdown-chevron.iconCardChevron{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-width: 1px;
    border-radius: 25px;
    border: 1px solid var(--light-elements-elements-3);
    width: 28px;
    height: 28px;
    position: absolute;
    right: 11px;
    bottom: 14px;
    color: var(--light-text-text-1)
}
.iconCardChevron i{
    color: var(--light-text-text-1);
    font-size: 0.8rem;
}
.iconCardChevron:hover{
    background-color: var(--light-primary-yellow-3);
}
.carddropdown-icon-ellipsis{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-radius: 25px;
    border: 1px solid var(--light-neutral-gray-3);
    width: 28px;
    height: 28px;
    color: var(--light-text-text-1);
    position: absolute;
    right: 56px;
    bottom: 14px;
}
.carddropdown-icon-ellipsisBg{
    background-color: var(--light-background-background-1);
}
.carddropdown-icon-ellipsisBg:hover{
    background-color: var(--light-primary-yellow-3);
}
.carddropdown-icon-ellipsis:hover{
    background-color: var(--light-primary-yellow-3);
}
.subContainer-cardDropDown{
    width: 100%;
    background-color: var(--light-background-background-2);
    border: 1px solid var(--light-elements-elements-1);
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden; /* Oculta el contenido que desborda el contenedor */
}
.subContainer-cardDropDown.hide{
    border: none;
    max-height: 0;
}

/*Dark mode*/
.dark .colorCardServices{
    background-color: var(--dark-primary-blue-1);
}
.dark .carddropdown-card-fluid span{
    color: var(--dark-text-text-1);
}
.dark .selectedText{
    color: var(--dark-text-text-3);
}
.dark .carddropdown-card-fluid{
    border: 1px solid var(--dark-neutral-gray-3);
    background-color: var(--dark-background-background-1);
}
.dark .subContainer-card-fluid{
    border: 1px solid var(--dark-neutral-gray-3);
    background-color: var(--dark-background-background-1);
}
.dark .subContainer-cardDropDown{
    background-color: var(--dark-background-background-2);
    border: solid 1px var(--dark-elements-elements-1);
}
.dark .iconCardChevron{
    border: solid 1px var(--dark-neutral-gray-3);
    color: var(--dark-text-text-1)
}
.dark .iconCardChevron i{
    color: var(--dark-text-text-1)
}
.dark .carddropdown-icon-ellipsis{
    border: solid 1px var(--dark-neutral-gray-3);
    fill: var(--dark-text-text-1);
    color: var(--dark-text-text-1);
}
.dark .carddropdown-icon-ellipsisBg{
    background-color: var(--dark-background-background-1);
}
.dark .carddropdown-optionsgroup{
    background-color: var(--dark-background-background-1);
}
.dark .carddropdown-item span{
    color: var(--dark-text-text-1);
}
.dark .carddropdown-item:hover{
    background-color: var(--dark-elements-elements-2);
}
