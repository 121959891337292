.producText{
    color: var(--light-text-text-1) !important;
}

.producTextSwitch{
    color: var(--light-text-text-2) !important;
}

.dark .producText{
    color: var(--dark-text-text-1) !important;
}

.dark .producTextSwitch{
    color: var(--dark-text-text-2) !important;
}