.lineContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    flex-wrap: wrap;
}

.inputsContainer{
    display: flex;
    justify-content: space-between;
    min-width: 100px;
}
.sectionButtons{
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}
.inputButton{
    display: flex;
    border-radius: 50px; 
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--light-elements-elements-3);
    background-color: var(--light-background-background-1);
    color: var(--light-text-text-1);
}

/*DARK MODE*/
.dark .inputButton{
    border: 1px solid var(--dark-elements-elements-3);
    background-color: var(--dark-background-background-1);
    color: var(--dark-text-text-1);

}