@import url("./../css-generated/variables.css");

.team_options-aside{
    display: flex;
    flex-direction: column;
    /*TODO: Asignar los colores que correspondan para el ligth dark*/
    background: var(--light-background-background-1);
    width: 400px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    border-radius: 10px 0 0 10px;
}
.team_options-aside-fixed{
    display: flex;
    flex-direction: column;
    /*TODO: Asignar los colores que correspondan para el ligth dark*/
    background: var(--light-background-background-1);
    width: 400px;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
    border-radius: 10px 0 0 10px;
}
.team_options-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid var(--light-elements-elements-5);
}
.team_options-title{
    font-size: 1.2em;
    font-weight: 700;
    margin: 0;
}
.team_options-close-button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    transition: all 0.5s;
}
.team_options-close-button:hover{
    background: #F0B669;
}
.team_options-body{
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 10;
}

.team_card-creation{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: none;
    margin: 5px 0;
    border-radius: 12px;
    border: 1px solid var(--light-elements-elements-5);
    padding: 15px;

}
.team_circle-image{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 10px;
    width: 40px;
    height: 40px;
    background: var(--dark-elements-elements-5);
    margin-right: 15px;
}
.team_circle-icon {
    text-align: center;
    font-size: 10px;
}
.team_card-info{
    text-align: justify;

}
.team_card-info-title{
    font-size: 1em;
    font-weight: 600;
}
.team_card-info-description{
    font-size: 0.8em;
    margin:0
}
.team_card-info-right {
    position: absolute;
    right: 40px;
}
.team_options-footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px; 
    background-color: #fff;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1); 
}
.modal-booking-footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px; 
}