.cardall-container{
    width: 100%;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--light-neutral-white);
}
.cardall-body{
    width: 200px;
    padding-right: 10px;
}

.cardall-timeColor{
    color: var(--light-neutral-gray-1);
}

.cardall-end{
    gap: 10px;
}

.dark .cardall-container {
    background-color: var(--dark-neutral-white);
}

.dark .cardall-timeColor{
    color: var(--dark-neutral-gray-1);

}