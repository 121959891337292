.descriptionServiceCharges{
    width: 85%;
    display: block;
}

.font-weight-bold{
    font-weight: bold;
}

.b-createServices{
    width: 161px;
}

.settings-section {
    border: 1px solid var(--light-neutral-gray-3) !important;
    padding: 15px;
    border-radius: 5px;
    background-color: var(--light-background-background-1);
}
.dark .settings-section {
    border: 1px solid var(--dark-neutral-gray-3) !important;
    background-color: var(--dark-background-background-1);
}

.settings-section h5 {
    font-weight: bold;
    color: var(--light-text-text-1);
    margin-bottom: 15px;
}

.dark .settings-section h5 {
    color: var(--dark-text-text-1);
}

.settings-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}

.option-text-settings {
    color: var(--light-text-text-1);
    display: flex;
    flex-direction: column;
    width: 100%;
}
.dark .option-text-settings {
    color: var(--dark-text-text-1);
}

.option-text-settings span {
    color: var(--light-text-text-1);
    font-weight: bold;
    font-size: 16px;
}

.dark .option-text-settings span {
    color: var(--dark-text-text-1);
}

.settingsDescription {
    color: var(--light-text-text-2);
    font-size: 14px;
    margin: 5px 0 0;
}

.dark .settingsDescription {
    color: var(--dark-text-text-2);
}

.labelDescription {
    color: var(--light-text-text-2);
    font-size: 14px;
}

.dark .labelDescription {
    color: var(--dark-text-text-2);
}

hr {
    margin: 5px 0;
    border-top: 1px solid var(--light-neutral-gray-1);
}

.dark hr {
    border-top: 1px solid var(--dark-neutral-gray-1);
}

.chevronColor{
    color: var(--light-text-text-1);
}

.dark .chevronColor{
    color: var(--dark-text-text-1);
}

.service-charges-list{
    width: 100%;
}

@media (max-width: 500px) {
    .descriptionServiceCharges{
        margin-top: 20px;
        width: 100%;
        display: block;
    }
}

.no-link-style {
    text-decoration: none; 
    color: inherit; 
}

.container {
    display: flex;
    flex-direction: column;
}

.containerButtonSaveSettings {
    display: flex;
    margin-top: auto; 
    padding: 16px;
    justify-content: flex-end;
}
.withButtonSaveSettings {
    width: 100px;
}
