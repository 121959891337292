
/* Estilo para el contenedor de Navbar */
.menu-contenedor {
  background-color: white;
  padding-top: 4px;
  padding-bottom: 0px;
  height: 50px;
  border-top: 1px solid rgb(237, 236, 236);
  margin-bottom: 30px;
  margin-top: 20px;
}

/* Estilo de la lista de opciones de menú */
.main-nav {
  list-style: none;
  padding: 0px;
  display: flex;
  align-items: center;
}

/* Estilo de cada elemento del menú */
.main-item {
  padding-top: 8px;
  position: relative;
  padding-bottom: 12px;
}

/* Estilo del enlace (pestaña) del menú */
.main-item a {
  text-decoration: none;
  color: rgb(96, 96, 96);
  padding: 10px 10px;
  border-radius: 5px;
  transition: color 0.3s ease;
  padding-bottom: 23px;
}


.main-item a:hover {
  text-decoration: none;
  color: rgb(46, 46, 46);
  padding: 10px 10px;
  border-radius: 5px;
  transition: color 0.4s ease;
  border-top: 4px solid #3e64c5;
  background-color: #f7f8fa;
  height: 38px;
  padding-bottom: 23px;
}

/* Estilo de la pestaña seleccionada */
.main-item.active a {
  color: #3e64c5;
  border-top: 4px solid #3e64c5;
  background-color: #f7f8fa;
}

.contai-checkbox {
  display: flex; /* Utiliza flexbox para alinear elementos horizontalmente */
  align-items: center; /* Centra verticalmente los elementos en el contenedor */
  justify-content: flex-start;
}

.custom-checkbox {
  min-width: 45px; /*Ancho del botón*/
  height: 25px; /* Altura del botón */
  background-color: #ccc; /* Color de fondo del botón */
  display: inline-block;
  position: relative;
  cursor: pointer;
  border-radius: 30px; /* Radio de los bordes para que parezca un botón circular */
  overflow: hidden; /* Oculta cualquier contenido que se desborde */
}

.mi-checkbox {
  display: none; /* Oculta el checkbox nativo */
}

.mi-checkbox + .custom-checkbox::before {
  content: "";
  width: 20px; /* Ancho del círculo interior */
  height: 20px; /* Altura del círculo interior */
  background-color: #fff; /* Color del círculo interior */
  border-radius: 50%; /* Hace que el círculo interior sea redondeado */
  position: absolute;
  top: 2.5px;
  left: 3px; /* Inicialmente, el círculo está a la izquierda */
  transition: left 0.2s; /* Agrega una animación suave de desplazamiento */
}

.mi-checkbox:checked + .custom-checkbox::before {
  left: 22px; /* Cuando está marcado, el círculo se desplaza a la derecha */
}

.mi-checkbox:checked + .custom-checkbox {
  background-color: #3e64c5; /* Cambia el color de fondo cuando está marcado */
}






.prueba .sub-container:nth-child(even){
  background-color: var(--light-background-background-1);
  border-radius: 10px;
}
.dark .sub-container:nth-child(even){
  background-color: var(--dark-background-background-1);
}

.flex{
  margin-top: 15px;
  margin-left: 10px;
  color: var(--light-neutral-gray-1)
}

.w-icon{
  font-size: 19px;
  margin-top: 3px;
}

.areas-interest{
  padding: 5px 20px 5px 20px;
  border: solid 1px var(--light-neutral-gray-1);
  background-color: var(--light-primary-blue-1);
  margin-right: 5px;
  color: var(--light-text-text-3);
  border-radius: 20px;
}
