.active-top-nav-link-view {
    border-top: solid 4px  var(--light-primary-blue-1) !important;
    background: var(--light-background-background-2) !important;
    color: var(--light-primary-blue-1) !important;
}

.dark .active-top-nav-link-view {
    border-top: solid 4px  var(--light-primary-blue-1) !important;
    background: var(--dark-background-background-2) !important;
    color: var(--light-primary-blue-1) !important;
}