.payroll-box{
    background: var(--light-background-background-1);
    border: 1px solid var(--light-elements-border-panels-1);
    border-radius: 8px;
    padding: 1rem 1rem;
}
.container-payroll {
    height: 50vh;
}

.dark .payroll-box{
    background: var(--dark-background-background-1);
}