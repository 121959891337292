.containerCardTeam {
    width: 100%;
    display: flex;
    position: relative;
    margin-right: 10px;
    flex-direction: row;
    align-items: center;
    min-height: 55px;
    border-width: 1px;
    border: solid 1px var(--light-elements-elements-1);
    border-radius: 10px;
    z-index: 1;
    background-color: var(--light-neutral-white);
    justify-content: space-between;
}

@media (max-width: 500px) {
    .containerCardTeam {
        width: 100%;
    }
    .subContainer-cardTeam {
        width: 100% !important;
    }
}
.image-carddropdown-check {
    border-radius: 50%;
    border: none;
    width: 45px;
    height: 45px;
    object-fit: cover;
}
.icon-color {
    color: var(--light-text-text-1);
}

.icon-color:hover {
    color: var(--light-text-text-6);
}
.dark .icon-color {
    color: var(--dark-text-text-1);
}
.dark .icon-color:hover {
    color: var(--dark-text-text-6);
}
.dark .containerCardTeam {
    background-color: var(--dark-neutral-white);
    border: solid 1px var(--dark-elements-elements-1);
}

.dropDownBarber-colorChecbox{
    color: var(--light-neutral-gray-3) !important;
}

.dark .dropDownBarber-colorChecbox{
    color: var(--dark-neutral-gray-3) !important;
}

.dropDownBarber-colorChecboxGray{
    color: var(--light-neutral-gray-1) !important;
}

.dark .dropDownBarber-colorChecboxBlue{
    color: var(--light-neutral-gray-3) !important;
}

.colorCard{
    background-color: var(--light-primary-blue-1);
}

.dark .colorCard{
    background-color: var(--dark-primary-blue-1);
}

.selected-text {
    color: var(--light-text-text-3);
}

.default-text {
    color: var(--light-text-text-2);
}

.dark .default-text {
    color: var(--dark-text-text-2);
}

.dark .selected-text {
    color: var(--dark-text-text-3);
}

.iconCard{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-width: 1px;
    border-radius: 25px;
    border: solid 1px var(--light-elements-elements-1);
    min-width: 28px;
    min-height: 28px;
}

.dark .iconCard{
    border: solid 1px var(--dark-elements-elements-1);
}

.iconCard:hover{
    background-color: var(--light-primary-yellow-1);
}

.bgColorYellow{
    background-color: var(--light-primary-yellow-1);
}

.dark .bgColorYellow{
    background-color: var(--dark-primary-yellow-1);
}

.subContainer-cardTeam{
    width: 100%;
    background-color: var(--light-background-background-2);
    margin-right: 10px;
    border-width: 1px;
    border: solid 1px var(--light-elements-elements-1);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
    top: -48px;
    overflow: hidden;
    padding-bottom: 15px;
    overflow: hidden; /* Oculta el contenido que desborda el contenedor */
    transition: max-height 0.3s ease-out; /* Transición para el efecto de despliegue */
    max-height: 0; /* Inicialmente oculto */
}

.dark .subContainer-cardTeam{
    background-color: var(--dark-background-background-2);
    border: solid 1px var(--dark-elements-elements-1);
}

.subContainer-cardTeam.show {
    max-height: 700px; /* Ajusta el valor según el contenido para el despliegue completo */
    top: -4px;
    border-top: none;
}

.subContainer-cardTeam.hide {
    max-height: 0; /* Oculta el contenido */
}

.descripcionCardTeam {
    padding-left: 10px;
    color: var(--light-text-text-1);
    font-weight: 600;
}

.dark .descripcionCardTeam {
    color: var(--dark-text-text-1);
}

.descripcionCardTeamFirst{
    padding-top: 60px;
    padding-left: 10px;
    color: var(--light-text-text-1);
    font-weight: 600;
}

.dark .descripcionCardTeamFirst {
    color: var(--dark-text-text-1);
}

.subcontainerDetailsTeam {
    border-radius: 10px;
    margin: 10px;
    background-color: var(--light-neutral-white);
    padding: 10px;
}

.dark .subcontainerDetailsTeam {
    background-color: var(--dark-neutral-white);
}

.subDescripcionCardTeam {
    margin-left: 10px;
    color: var(--light-text-text-2);
    font-weight: 500;
}

.dark .subDescripcionCardTeam{
    color: var(--dark-text-text-2);
}

.valueCardTeam {
    color: var(--light-text-text-2);
    margin-left: 10px;
}

.dark .valueCardTeam {
    color: var(--dark-text-text-2);
}

.iconComission{
    fill: var(--light-text-text-2);
    margin-left: 5px;
    width: 24px; 
    height: 24px; 
    border-radius: 50%; 
    background-color: transparent; 
    transition: background-color 0.3s ease; 
    display: flex;
    align-items: center;
    justify-content: center;
}

.dark .iconComission{
    fill: var(--dark-text-text-2);
}

.iconComission:hover{
    background-color: #FFC67A; 
    fill: var(--light-text-text-2);
}

.text-Details{
    margin-left: 5px;
    font-weight: 600;
}

