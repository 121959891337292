.dropdown-profile .dropdown-item{
    padding: 4px 10px 4px 5px;
    border: none;
}

/* seccion superior imagen y nombre */
.container-round-image{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
    background: var(--light-background-background-2);
    display: flex;
    justify-content: center;
    align-items: center;
}
.container-round-image i{
    font-size: 24px;
}
.container-round-image img, .container-round-image-small img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.dropdown-profile .textUserTitle{
    font-weight: bold;
    font-size: 14px;
    color: var(--light-text-text-2);
    margin: 0 10px;
}
.dropdown-profile .textSubtitleProfile {
    font-weight: light;
    font-size: 12px;
    color: var(--light-text-text-2);
    margin: 0 10px;
}
.dark .dropdown-profile .textUserTitle{
    color: var(--dark-text-text-2);
}
.dark .dropdown-profile .textSubtitleProfile {
    color: var(--dark-text-text-2);
}
.selection-profiles-section button {
    padding-left: 0px !important;
}

/* seccion de elementos */
.dropdown-item-icon-left i {
    margin-right: 10px;
}

.dropdown-profile .textUser {
    font-weight: 400;
    font-size: 14px;
    color: var(--light-text-text-2);
    margin: 0 10px;
}

.dark .dropdown-profile .textUser {
    color: var(--dark-text-text-2);
}

.dropdown-profile .dropdown-item i{
    color: var(--light-text-text-2);
    font-size: 20px;
}

.dark .dropdown-profile .dropdown-item i, .dark .dropdown-profile .dropdown-item span{
    color: var(--dark-text-text-2);
}

/* seccion de seleccion de perfiles */
.dropdown-profile .container-round-image-small{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
    background: var(--light-background-background-2);
    display: flex;
    justify-content: center;
    align-items: center;
}
.dropdown-profile .container-round-image-small i{
    color: var(--light-text-text-2);
    font-size: 17px;
}

.dropdown-profile .item-active .textUser {
    font-weight: bold;
    color: var(--light-secondary-blue-king);
}

.dropdown-profile .item-active .textSubtitleProfile {
    color: var(--light-secondary-blue-king);
}

.dark .container-round-image-small{
    background: var(--dark-background-background-1);
}
.dark .container-round-image-small i{
    color: var(--dark-text-text-2);
}


.dark .dropdown-profile .item-active .textUser {
    color: var(--dark-secondary-blue-king);
}

.dark .dropdown-profile .item-active .textSubtitleProfile {
    color: var(--dark-secondary-blue-king);
}

.checkeable-profile{
    color: var(--light-secondary-blue-king) !important;
}
.dark .checkeable-profile{
    color: var(--dark-secondary-blue-king) !important;
}
