.simplecard-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0;
    border-radius: 12px;
    width: 100%;
    border: 1px solid var(--light-elements-elements-5);
    padding: 10px;
    background-color: var(--light-background-background-1);
    text-align: left;

}
.simplecard-imageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    margin-right: 15px;
    background: var(--light-elements-border-panels-2);
    color: var(--light-text-text-3);
    font-size: 20px;

}
.simplecard-selected{
    background-color: var(--light-primary-blue-1);
    color: var(--light-text-text-3)
}
.simplecard-textSelected{
    color: var(--light-text-text-3);
}

.dark .simplecard-container{
    border: 1px solid var(--dark-elements-elements-5);
    background-color: var(--dark-background-background-1);

}
.dark .simplecard-imageContainer{
    background: var(--dark-elements-border-panels-2);    
    color: var(--dark-text-text-3);
}
.dark .simplecard-selected{
    background-color: var(--dark-primary-blue-1);
    color: var(--dark-text-text-3)
}
.dark .simplecard-textSelected{
    color: var(--dark-text-text-3);
}