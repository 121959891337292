.cardBlue{
    background: var(--dark-text-text-2);
    border: none !important;
    border-radius: 10px;
}

.dark .cardBlue{
    background: var(--dark-status-info-2);

}
.cardMessageBlue{
    color: var(--light-status-info);
}

.messageBlue > p{
    color: #0252B0;
} 

.dark .messageBlue{
    color: var(--dark-status-info);
}

.dark .messageBlue > p{
    color: var(--dark-status-info);
} 