.buttonInfo-button{
    background: none;
    border: none;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
}
.buttonInfo-button i{
    color: var(--light-text-text-1);
}

.dark .buttonInfo-button i{
    color: var(--dark-text-text-1);
}