.cardStyle-lineUp {
    width: 70%;
    align-items: center;
    border-radius: 13px;
    border: solid;
    flex-direction: row;
    display: flex;
    margin-right: 10px;
    height: 55px;
    justify-content: space-between;
    position: relative;
}
.cardColorLineUp{
    background-color: var(--light-background-background-1);
    border-color: var(--light-elements-border-panels-1);
    border-width: 1px;
}
.dark .cardColorLineUp{
    background-color: var(--dark-background-background-1);
    border-color: var(--dark-elements-border-panels-1);

}
.textContainerStylelineup {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
}
.textCardLineUp {
    font-size: var(--light-font-size-2);
    font-weight: var(--light-font-weights-source-sans-pro-2);
    margin: 0;
}
.colorCardLineUp{
    background-color: var(--light-primary-blue-1) !important;
}
.selected-text-lineup {
    color: #ffffff;
}
.color-text-title-lineup {
    color: var(--light-text-text-1);
}
.dark .color-text-title-lineup {
    color: var(--dark-text-text-1);
}
.iconCardLineUp{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-width: 1px;
    border-radius: 25px;
    border: solid 1px var(--light-neutral-gray-3);
    width: 28px;
    height: 28px;
}
.iconCardLineUp:hover{
    background-color: var(--light-primary-yellow-3);
}
.text-DetailslineUp{
    margin-left: 5px;
    font-weight: 600;
}
.container-internal-lineup{
    width: 70%;
    display: none;
    background-color: var(--light-elements-elements-2);
    margin-left: 0px;
    margin-right: 0px;
    border-width: 1px;
    border: solid 1px var(--light-elements-border-panels-1);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 10px;
    position: relative;
    top: -15px;
    overflow: hidden;
    padding-bottom: 15px;
    align-items: center;
}
.dark .container-internal-lineup{
    background-color: var(--dark-elements-elements-2);
    border: solid 1px var(--dark-elements-border-panels-1);
}
.container-hidden-lineup {
    display: block;
}
.icon-color-line-up{
    color: var(--light-text-text-1);
}

.dark .icon-color-line-up{
    color: var(--dark-text-text-1);
}

.icon-color-select-line-up{
    color: var(--dark-text-text-1);
}


