.upload-gallery-container{
    margin-bottom: 50px;
}

.text{
    color: var(--light-text-text-1);
}

.section-uploads {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.upload-gallery-title{
    color: var(--light-text-text-1);
}

.upload-gallery-item-void{
    width: 120px;
    height: 120px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    background: var(--light-primary-blue-1);
}

.upload-gallery-item-void i{
    font-size: 26px;
}

.upload-gallery-item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.upload-gallery-item{
    width: 120px;
    height: 120px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

span.delete-image {
    position: absolute;
    top: 2px;
    right: 2px;
    background: var(--light-neutral-white);
    border-radius: 50%;
    color: var(--light-status-error);
    font-size: 20px;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
}

/* ##################
    MODO OSCURO 
###################*/
.dark .upload-gallery-title{
    color: var(--dark-text-text-1);
}
.dark .upload-gallery-item-void{
    background: var(--dark-primary-blue-1);
}
.dark .text{
    color: var(--dark-text-text-1);
}

.dark span.delete-image {
    background: var(--dark-neutral-white);
    color: var(--dark-status-error);
}