.apointmentCard {
    height: 130px;
    background-color: var(--light-background-background-1);
    border-radius: 10px;
    border: solid 1px var(--light-elements-border-panels-1);
}
.dark .apointmentCard{
    background-color: var(--dark-background-background-1);
    border-color: var(--dark-elements-border-panels-1);
}
.customerViewButton {
    height: 30%;
    border: var(--light-elements-elements-1) solid 1px;
    border-radius: 12px;
    overflow: hidden;
    background-color: var(--light-primary-blue-5);
}
.dark .customerViewButton{
    border: var(--dark-elements-elements-1) solid 1px;
    background-color: var(--dark-primary-blue-5);
}
.appoitmentCardImage {
    display: flex;
    background-color: var(--light-primary-blue-1);
    border-radius: 50%;
    min-width: 50px;
    min-height: 50px;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border: solid 1px var(--dark-elements-border-panels-1);
    text-align: center;
    align-content: center;
    color: var(--light-background-background-1);
}
.appoitmentCardImageContainer{
    width: 55px;
    justify-content: center;
    align-items: center;
}
.status-missed {
    background-color: var(--light-secondary-red);
    color: var(--dark-text-text-1) !important;
}

.status-complete {
    background-color: var(--light-secondary-green);
}

.status-comming {
    background-color: var(--light-primary-blue-1);
    color: var(--dark-text-text-1) !important;
}
.dark .status-comming{
    color: var(--light-text-text-1) !important;

}

.status-rescheduled {
    background-color: var(--light-primary-yellow-1);
}

.status-cancel {
    background-color: var(--light-text-text-4);
}

.status-pending {
    background-color: var(--light-primary-yellow-1);
}

.status-charge {
    background-color: var(--light-secondary-green);
}

.status-container {
    line-height: 15px;
    text-align: center;
    width: 70px;
    height: 20px;
    border-radius: 5px;
    color: var(--light-text-text-6);
    font-size: 12px;
    border: 2px solid var(--light-elements-elements-1);
    font-weight: 500;
}

.dark .status-container{
    color: var(--dark-text-text-6);
    border: 2px solid var(--dark-elements-elements-1);
}
.card-title{
    color: var(--light-text-text-1);
}
.text-info-card{
    color: var(--light-text-text-2);
    font-size: 13px;
}
.dark .card-title{
    color: var(--dark-text-text-1);
}
.dark .text-info-card{
    color: var(--dark-text-text-2);
}

.icon-view-card {
    color: #000 !important;
}

.dark .text-card-button-right{
    color: var(--dark-text-text-6);
}