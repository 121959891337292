.taxesContainer{
    background: var(--light-background-background-1);
}

.titleTaxes{
    color: var(--light-text-text-1);
}

.textTaxes{
    color: var(--light-text-text-2);
    font-size: 18px;
    margin-left: 42px;
}

.marginless{
    margin: 0;
}

/* MODO OSCURO */

.dark .taxesContainer{
    background: var(--dark-background-background-1);
}

.dark .textTaxes{
    color: var(--dark-text-text-2);
}

.dark .titleTaxes{
    color: var(--dark-text-text-1);
}