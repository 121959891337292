.buttonAddOutline{
    border: 1px solid var(--light-elements-elements-1);
    background: white;
    display: block;
    width: 100%;
    padding: 8px 10px;
    border-radius: 10px;
    margin: 4px 0px;
    color: var(--light-text-text-1);
    overflow: hidden;
}

html.dark 
.buttonAddOutline{
    border: 1px solid var(--dark-elements-elements-1);
    background: transparent;
    display: block;
    width: 100%;
    padding: 8px 10px;
    border-radius: 10px;
    margin: 4px 0px;
    color: var(--dark-text-text-1);
}
.team-close-button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    transition: all 0.5s;
}