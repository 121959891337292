.button-toggle-content{
    background: var(--light-background-background-1);
    display: flex;
    border-radius: 10px;
    padding: 4px;
    width: 100%;
}
.button-toggle {
    background: var(--light-background-background-1);
    font-weight: 600;
    width: 50%;
    border-radius: 10px;
    padding: 8px; 
    color: var(--light-text-text-2);
    text-align: center; 
    display: flex; 
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.button-toggle.selected {
    background-color: var(--light-primary-blue-1);
    color: var(--light-text-text-3);
}

.dark .button-toggle-content{
    background: var(--dark-background-background-1);
}
.dark .button-toggle.selected {
    background-color: var(--dark-primary-blue-1);
}
.dark .button-toggle {
    background: var(--dark-background-background-1);
    color: var(--dark-text-text-2);
}
