.pricing-box{
    background: var(--light-background-background-1);
    border: 1px solid var(--light-elements-border-panels-1);
    border-radius: 8px;
    max-width: 60%;
    padding: 1rem 1rem;
}

.text-pricing{
    color: var(--light-text-text-2);
    font-size: 18px;
}

.marginless{
    margin: 0;
}

.title-pricing{
    color: var(--light-text-text-1);
}

.container-pricing{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.button-toogle-pricing{
    display: flex;
    background-color: transparent;
    border: none;
    text-align: left;
    justify-content: center;
    align-items: center;
}

.checkbox-pricing{
    border: 1px solid var(--light-elements-border-panels-1);
    border-radius: 50px;
    height: 24px;
    width: 24px;
    margin: 0 10px;
}

.checkbox-pricing-active{
    border-radius: 50px;
    height: 24px;
    width: 24px;
    background-color: var(--light-primary-blue-1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
}

.white-circle{
    height: 10px;
    width: 10px;
    border-radius: 50px;
    background-color: var(--light-neutral-white);
}
/* MODO OSCURO */

.dark .pricing-box{
    background: var(--dark-background-background-1);
}

.dark .text-pricing{
    color: var(--dark-text-text-1);
}

.dark .title-pricing{
    color: var(--dark-text-text-1);
}

.dark .checkbox-pricing-active{
    background-color: var(--dark-primary-blue-1);
}

.dark .white-circle{
    background-color: var(--dark-neutral-white);
}