.bold-card {
    background: var(--light-background-background-1);
    border-radius: 10px;
    flex-direction: column;
    width: 96%;
    margin: 10px 2%;
    padding: 10px 5px;
}
.title-card {
    margin-bottom: 0px;
    color: var(--light-neutral-gray-2);
}
.value-card {
    font-size: 34px;
    margin-bottom: 0px;
    color: var(--light-neutral-gray-1);
}

.value-description{
    margin-bottom: 0px;
    color: var(--light-neutral-gray-1);
}

/* ########################
    MODO OSCURO
########################## */
.dark .bold-card {
    background: var(--dark-background-background-1);
}
.dark .title-card{
    color: var(--dark-neutral-gray-2);
}
.dark .value-card {
    color: var(--dark-text-text-1);
}
.dark .value-description {
    color: var(--dark-text-text-1);
}
