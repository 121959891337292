
/* Form products */
/* .app {
    margin-top: 40px;
    width: 100%;
    min-height: calc(100vh - 150px);
} */
.app.container{
    position: relative;
    flex: 1;
    margin-bottom: 20px;
}
.card-option {
    display: block;
    text-align: center;
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    overflow: hidden;
    margin: 10px 5px;
    box-shadow: 2px 2px 12px 0px #c4c4c4;
}
.card-option.selected {
    border: 2px solid #1876d2;
    transform: scale(1.1);
}

.card-option-img-content {
    display: flex;
    padding: 0px;
    overflow: hidden;
    width: 100%;
    align-items: center;
    justify-content: center;
}
img.card-option-img {
    display: block;
    width: 90%;
}
.card-option-content {
    display: flex;
    min-height: 45px;
    padding: 5px;
    align-items: center;
    justify-content: center;
    background: #ededed;
    font-weight: 600;
}

/* dropdown */
.dropdown-item {
    margin: 3px 0px;
    padding: 4px 10px;
    border-bottom: 1px solid #f2f2f2;
}
.dropdown-item-group{
    display: flex;

}
.dropdown-item-icon{
    display: block;
    width: 25px;
    margin-right: 3px;
}
.dropdown-item-icon-left span {
    padding-left: 10px;
}
.dropdown-item-title{
    display: block;
    font-size: 14px;
}
.text-button{
    font-size: var(--light-font-size-1);
    font-weight: var(--light-font-weights-source-sans-pro-0);
}

/* table */
.bold-table-note {
    max-width: 920px;
    padding: 0px 20px;
    margin: 10px 0px;
}

/* btn table actions*/
.btn-table-actions.wx2 {
    width: 52px;
    position: relative;
}
span.xsmall {
    font-size: 8px !important;
    position: absolute;
    top: 20px;
    left: 0px;
    right: 0;
}


/* card products selected in form */
.card-option-selected {
    border: 2px solid #ef0000;
}

/*forms*/
.form-select-color{
    width: 40px;
    height: 40px;
    background: #FCC200;
    border-radius: 50%;
    border: 2px solid #FCC200;
}
.barberlytics-textfield{
    border-radius: 10px;
}
.dark .barberlytics-textfield{
    border-radius: 10px;
}
.barberlytics-textfield.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-error.MuiInputBase-formControl.MuiInputBase-sizeSmall.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    background: white !important;
}
.barberlytics-textfield div{
    border-radius: 10px;
    }

.barberlytics-multiple-section{
    border: 1px solid #A4A4A4;
    border-radius: 15px !important;

}
.barberlytics-multipleSelects{
    background: white;
    /* border: 1px solid #A4A4A4; */
    padding: 15px 5px;
}
.barberlytics-multipleSelects div:focus{
    background: white;

}
.barberlytics-multiple-element{
    background: #4D689E !important;
    color: white !important;
}
.barberlytics_text-field div{
    border-radius: 10px;
    background-color: var(--light-neutral-white);
}

.inputNumber div{
    width: 49%;
}
.w-98 div{
    width: 98%;
}
.w-40 {
    width: 40%;
}
.numberClients{
    align-items: center;
    display: flex;
    margin-bottom: 2px;
    margin-left: 15px;
    font-size: 15px;
}
/*Views*/

element.style {
}
.viewsection-circle-image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
}
.viewsection-circle-image img{
    width: 100%;
}
.details-container {
    background: var(--light-background-background-1);
    border-radius: 10px;
    padding: 15px;
}
.details-container-subtitle{
    color: var(--light-text-text-1);
    font-weight: 300;
}
.dark .details-container{
    background: var(--dark-background-background-1);;
}
.dark .details-container-subtitle{
    color: var(--dark-text-text-1);
}
.service-color{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border-style: none;
}
.button-icon{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.multipleinputs-container{
    display: flex;
}
.multipleinputs-container span{
    font-size: 12px;
}
.multipleinputs-container button{
    width: 25px;
    height: 25px;
    margin: 10px 10px
}


