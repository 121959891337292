.body-right-dinamic{
    background: var(--light-background-background-2);
    padding: 0px!important;
    display: flex;
    flex-direction: column;
}

.content-scroll {
    overflow: auto;
    flex: 1;
}

.dark .body-right-dinamic{
    background: var(--dark-background-background-2);
}

.card-block {
    margin: 0px 15px 0px 15px!important;
}

.nav-filter-block{
    margin: 0px 0px 5px!important;
    overflow-x: auto; 
    white-space: nowrap; 
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; 
}