.paddingSubTitleAddres{
    margin-bottom: 0px !important;
}

.descriptionServiceCharges{
    width: 85%;
    display: block;
}

.font-weight-bold{
    font-weight: bold;
}

.b-createServices{
    width: 161px;
}

.settingsCard-section {
    border: 1px solid var(--light-neutral-gray-3) !important;
    padding: 15px;
    border-radius: 5px;
    background-color: var(--light-background-background-1);
}
.dark .settingsCard-section {
    border: 1px solid var(--dark-neutral-gray-3) !important;
    background-color: var(--dark-background-background-1);
}

.settingsCard-section h5 {
    font-weight: bold;
    color: var(--light-text-text-1);
    margin-bottom: 15px;
}

.dark .settingsCard-section h5 {
    color: var(--dark-text-text-1);
}

.titleListOfInvoices{
    font-weight: bold;
    color: var(--light-text-text-1);
    margin-bottom: 15px;
    font-size: 20px;
}

.dark .titleListOfInvoices{
    color: var(--dark-text-text-1);
}

.settingsCard-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}

.option-text-settings {
    color: var(--light-text-text-1);
    display: flex;
    flex-direction: column;
    width: 100%;
}
.dark .option-text-settings {
    color: var(--dark-text-text-1);
}

.option-text-settings span {
    color: var(--light-text-text-1);
    font-weight: bold;
    font-size: 16px;
}

.dark .option-text-settings span {
    color: var(--dark-text-text-1);
}

.text-addres {
    color: var(--light-text-text-2);
    font-size: 16px;
}

.dark .text-addres  {
    color: var(--dark-text-text-2);
}

.settingsDescriptionBilling {
    color: var(--light-text-text-2);
    font-size: 14px;
    margin: 5px 0 0;
}

.dark .settingsDescriptionBilling {
    color: var(--dark-text-text-2);
}

.divisorBilling {
    margin: 5px 0;
    border-top: 1px solid var(--light-neutral-gray-1);
}

.dark .divisorBilling {
    border-top: 1px solid var(--dark-neutral-gray-1);
}

.paddingCardPay {
    padding-bottom: 7px;
    padding-top: 5px;
}

.buttonBilling{
    width: 100%;
}