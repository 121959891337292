@import url("./../../../../../assets/css-generated/variables.css");

.hide-scroll{
    overflow: hidden;
}

.padding-none{
    padding: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.selection-oppacity-booking{
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgb(145 145 145 / 29%);
    z-index: 5;
    top: 0;
    left: 0;
}
.rightmodal-aside-booking {
    display: flex;
    flex-direction: column;
    background: var(--light-background-background-1);
    width: 35vh;
    height: 66vh;
    position: sticky;
    left: 100%;
    top: 40%;
    right: 0;
    z-index: 2;
    border-radius: 0px 0 0 0px;
    box-shadow: 0px 4px 10px 3px #0000002e;
    margin-top: 2px;
}

.rightmodal-header-booking{
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    border-bottom: 1px solid var(--light-elements-elements-5);
    color: var(--light-text-text-1);
    background: var(--light-background-background-1);
    box-shadow: 0px 0px 5px #00000042;
    z-index: 1;
    flex-wrap: wrap;
}
.content-header-modal-booking{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.rightmodal-title-booking{
    font-size: 1.2em;
    font-weight: 700;
    margin: 0;
}
.rightmodal-header-booking .title-modal-booking{
    flex: 4;
    align-items: flex-start;
}

.rightmodal-close-button-booking{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    transition: all 0.5s;
}
.rightmodal-close-button-booking:hover{
    background-color: var(--light-primary-yellow-4);
}
.rightmodal-body-booking{
    display: flex;
    flex: 10 1 auto;
    overflow: auto;
    flex-direction: column;
    padding: 15px;
    color: var(--light-text-text-2);
}
.rightmodal-footer{
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
    border-top: 1px solid var(--light-elements-elements-5);
    background: var(--light-background-background-1);
}
.icon-xmark {
    color: var(--light-text-text-1);
}
.icon-xmark:hover{
    color: var(--light-text-text-6);
}


.dark .rightmodal-aside-booking{
    background: var(--dark-background-background-1);
}
.dark .rightmodal-header-booking{
    color: var(--dark-text-text-1);
    border-bottom: 1px solid var(--dark-elements-elements-5);
    background-color: var(--dark-background-background-1);
}
.dark .rightmodal-body{
    color: var(--dark-text-text-2)
}
.dark .rightmodal-footer{
    border-top: 1px solid var(--dark-elements-elements-5);
    background-color: var(--dark-background-background-1);
}
.dark .rightmodal-close-button-booking:hover{
    background-color: var(--dark-primary-yellow-4);
}

.dark .icon-xmark {
    color: var(--dark-text-text-1);
}

.dark .icon-xmark:hover{
    color: var(--dark-text-text-6);
}
