@import "./../../../assets/css-generated/variables.css";

.centralmodalOptions-overlay{
    width: 100%;
    height: 100vh;
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(145 145 145 / 29%);
}
.centralmodalOptions-container{
    position: fixed;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--light-background-background-1);
    max-width: 85%;
    width: 650px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
}
.centralmodalOptions-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px;
    color: var(--light-text-text-1);
    border-bottom: 1px solid var(--light-elements-elements-5);
    color: var(--light-text-text-1);
}
.centralmodalOptions-title{
    font-size: 1.2em;
    font-weight: 600;
    margin: 0;
}
.centralmodalOptions-button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    transition: all 0.5s;
    color: var(--light-text-text-1);
}
.centralmodalOptions-button:hover{
    background-color: var(--light-primary-yellow-4);
}
.centralmodalOptions-button:active{
    background-color: var(--light-primary-yellow-4);
}
.centralmodalOptions-optionsgroup{
    width: 200px;
}
.centralmodalOptions-option button{
    border: none;
    background: none;
}
.centralmodalOptions-option span{
    font-size: 14px;
}
.centralmodalOptions-option:hover{
    background-color: var(--light-elements-elements-2);
    cursor: pointer;
    color: var(--light-text-text-3);
}
.centralmodalOptions-body{
    padding: 15px;
    display: flex;
    flex-direction: column;
    color: var(--light-text-text-2);
    background-color: var(--light-background-background-2);
    max-height: 500px;
    overflow: auto ;
}
.centralmodalOptions-body::-webkit-scrollbar {
    width: 5px; /* Ancho de la barra de scroll */
}
.centralmodalOptions-body::-webkit-scrollbar-track {
    background: var(--light-background-background-1); /* Fondo de la pista */
}
.centralmodalOptions-body::-webkit-scrollbar-thumb {
    background: var(--light-elements-elements-5); /* Color de la barra */
    border-radius: 10px; /* Bordes redondeados */
}
.centralmodalOptions-body::-webkit-scrollbar-thumb:hover {
    background: var(--light-elements-elements-4); /* Color al pasar el mouse */
}
/* Para Firefox */
.centralmodalOptions-body{
    scrollbar-width: thin; /* Grosor de la barra */
    scrollbar-color: var(--light-elements-elements-5) var(--light-background-background-1); /* Color de la barra y la pista */
}

/* dark */
.dark .centralmodalOptions-container{
    background: var(--dark-background-background-1);
}
.dark .centralmodalOptions-header{
    border-bottom: 1px solid var(--dark-elements-elements-5);
    color: var(--dark-text-text-1);
}
.dark .centralmodalOptions-button{
    color: var(--dark-text-text-1);
}
.dark .centralmodalOptions-button:hover{
    background-color: var(--dark-primary-yellow-4);
    color: var(--dark-text-text-3);
}
.dark button.btn.centralmodalOptions-button.show{
    background-color: var(--dark-primary-yellow-4);
    color: var(--dark-text-text-3);
}
.dark .centralmodalOptions-optionsgroup{
    background-color: var(--dark-background-background-2);
}
.dark .centralmodalOptions-option span{
    color: var(--dark-text-text-2);
}
.dark .centralmodalOptions-option:hover{
    background-color: var(--dark-elements-elements-2);
}
.dark .centralmodalOptions-body{
    color: var(--dark-text-text-2);
    background-color: var(--dark-background-background-2);
}
.dark .centralmodalOptions-body::-webkit-scrollbar-track {
    background: var(--dark-background-background-1); /* Fondo de la pista */
}
.dark .centralmodalOptions-body::-webkit-scrollbar-thumb {
    background: var(--dark-elements-elements-5); /* Color de la barra */
    border-radius: 10px; /* Bordes redondeados */
}
.dark .centralmodalOptions-body::-webkit-scrollbar-thumb:hover {
    background: var(--dark-elements-elements-4); /* Color al pasar el mouse */
}
/* Para Firefox */
.dark .centralmodalOptions-body {
    scrollbar-width: thin; /* Grosor de la barra */
    scrollbar-color: var(--dark-elements-elements-5) var(--dark-background-background-1); /* Color de la barra y la pista */
}
