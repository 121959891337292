.stepper-text{
    color: var(--light-status-info) !important;
    display: flex;
    justify-content: center;
}

.stepper-div{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 0 10px;
    z-index: 1;
}
.title-screen{
    font-size: var(light-font-size-9);
    font-weight: var(light-font-weights-source-sans-pro-1);
    font-family: var(light-font-families-source-sans-pro);
}

.color-text{
    color: var(--light-text-text-1);
}
.dark .color-text{
    color: var(--dark-text-text-1);
}

.text-screen{
    font-size: var(light-font-size-4);
    font-weight: var(light-font-weights-source-sans-pro-0);
    font-family: var(light-font-families-source-sans-pro);
}
.container-box{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 60vh;
}
.container-content{
    width: 70%;
}
.container-button{
    max-width: 40%;
}
.footer-buttons{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: var(--light-background-background-1);
    padding: 20px 10px;
}
.dark .footer-buttons{
    background-color: var(--dark-background-background-1);
}
.container-failed{
    background-color: var(--light-status-error);
    color: var(--dark-text-text-1);
    height: 2em;
    width: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.image-create-users {
    max-width: 100%;
}

.step-import{
    margin-right: 130px;
}

.color-step-text{
    color: var(--light-text-text-6);  
}

.dark .color-step-text{
    color: var(--dark-text-text-6);  
}

.container-box-step .MuiStepper-root{
    width: 100%;
}

@media(min-width: 900px){
    .container-box-step{
        width: 48%;
    }
}

@media(max-width: 768px){
    .container-box-step{
        width: 100%;
    }
}


.css-zpcwqm-MuiStepConnector-root{
    left: calc(-50% + 8px) !important;
    right: calc(40% + 20px) !important;
}

.button-width-import{
    width: 55% !important;
}