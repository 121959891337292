.title-notes{
    color: var(--light-text-text-2) !important;
    width: 100%;
    text-transform: capitalize !important;
}

.title-profile-customers{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-3);
    font-weight: var( --light-font-weights-source-sans-pro-2);
    color: var(--light-text-text-1);
}

.MuiTabs-scroller::-webkit-scrollbar {
    display: none;
}

/*dark*/
.dark .title-notes{
    color: var(--dark-text-text-2) !important;
}

.dark .title-profile-customers{
    color: var( --dark-text-text-1);
}

