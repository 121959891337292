.cardContainer {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: solid 1px;
    padding: 10px;
}

.cardBg{
    background-color: var(--light-background-background-1);
    border-color: var(--light-elements-border-panels-1);
}

.dark .cardBg{
    background-color: var(--dark-background-background-1);
    border-color: var(--dark-elements-border-panels-1);

}

.bodyContainerText {
    margin-left: 15px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.buttonContainerStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: solid;
    border-width: 1px;
}

.borderColorButton {
    border-color: var(--light-elements-elements-1);
    background-color: var(--light-background-background-1);
    color: var(--light-text-text-1);
}

.dark .borderColorButton {
    border-color: var(--dark-elements-elements-1);
    color: var(--dark-text-text-1);
    background-color: var(--dark-background-background-1);
}

.titleTextCard {
    margin-bottom: 5px;
    margin-top: 10px;
}

.dark .titleTextCard {
    color: var(--dark-text-text-1);
}

.descriptionTextCard {
    margin-right: 10px;
    margin-bottom: 5px;
}

.dark .descriptionTextCard {
    color: var(--dark-text-text-2);
}

.w-auto{
    width: auto !important;
}