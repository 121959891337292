.notification-column {
    cursor: pointer;
}

.notification-container {
    padding: 10px;
}

.notification-content-container {
    display: flex;
    align-items: flex-start;
}

.notification-content-text {
    flex: 1;
    line-height: 24px;
}

.notification-point {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-left: 5px;
    margin-top: 10px;
}

.notification-point-unread {
    background-color: var(--light-secondary-blue-king);
}

.notification-point-read {
    background-color: var(--light-secondary-red);
}

.dark .notification-point-unread {
    background-color: var(--light-secondary-blue-king);
}

.dark .notification-point-read {
    background-color: var(--light-secondary-red);
}