/*
Estos ficheros contienen las clases y estilos definitivos de la aplicacion - no de estilos de barberlytics.
*/
html.dark {
    background: var(--dark-background-background-2);
    transition: .3s linear all;
}

.dark button.btn-action-dash.dropdown-toggle {
    color: var(--dark-text-text-1);
}
.dark .background-1{
    background-color: var(--dark-background-background-1);
}

/* login */
.dark
.section-login {
    background: var(--dark-background-background-1);
    box-shadow: none;
    border: 1px solid var(--dark-elements-border-panels-1);
    color: var(--dark-text-text-2);
}

.dark .title-login {
    color: var(--dark-text-text-1);
}
.dark button.help-float {
    background: var(--dark-elements-elements-10);
}
.dark button.help-float span.material-symbols-outlined{
    color: var(--dark-text-text-2);
}

/* 
.MuiInputBase-input {
    border: 1px solid var(--dark-neutral-gray-2);
    border-radius: 4px;
} */

.dark
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border-color: var(--dark-neutral-gray-2);
}

.dark
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.dark 
.MuiOutlinedInput-notchedOutline {
    border-color: var(--dark-neutral-gray-2) !important;
}
.dark input{
    color: var(--dark-text-text-2);
}

.dark
.text-box-policies {
    display: block;
    background: var(--dark-background-background-2);
    min-height: 300px;
    max-height: 360px;
    border-radius: 4px;
    padding: 0px;
    overflow: hidden;
}

/* ################
Text 
################*/
.dark
.regular {
    color: var(--dark-text-text-2);
}

.dark
.light {
    color: var(--dark-text-text-2);
}
.dark
.small-text{
    color: var(--dark-text-text-2);
}

.dark .text-green {
    color: rgb(5, 171, 11);
}

.dark .text-orange {
    color: rgb(219, 129, 38);
}

.dark .text-red {
    color: var(--dark-status-error);
}

/*  ################
    ASIDE
    ################ */
.dark
.aside {
    background: var(--dark-primary-blue-2);
}

/* MENU ITEMS */
.dark
li.menu-item a {
    color: var(--dark-text-text-3);
}

.dark
li.menu-item a>div svg path {
    fill: var(--dark-text-text-3);
}

.dark
li.menu-item a:hover>div svg path {
    fill: var(--dark-text-text-3);
}

.dark
li.menu-item>a:hover {
    background: var(--dark-primary-blue-3);
    color: var(--dark-text-text-3);
    box-shadow: inset 4px 0px 0px 0px var(--dark-primary-yellow-1);
}

.dark li.menu-item span.material-symbols-outlined {
    color: var(--dark-text-text-3);
}


/* MAIN HEADER */
.dark
.container-menu {
    background: var(--dark-background-background-1);
    border-bottom: 1px solid #666666;
}

.dark span.material-symbols-outlined{
    color: var(--dark-text-text-1);
}

.dark .title-dashboard {
    color: var(--dark-text-text-1);
}

.dark .btn-action-dash{
    color: var(--dark-text-text-1);
}

.dark .btn-action-bell{
    color: var(--dark-text-text-1);
}

.dark span.location-label {
    color: var(--dark-text-text-1);
}

.dark
.dropdown-toggle.btn-location {
    border: 1px solid var(--dark-elements-border-panels-1);
}

/* CONTENT - CONTENIDO - PANTALLAS - SCREENS */
.dark
.title-page {
    color: var(--dark-text-text-1);
}

/*  ################
    SECTION PAGE CHILD MENU
################ */
/* .dark nav.child-menu a.active {
    border-top: solid 4px  var(--dark-primary-blue-1);
    background: var(--dark-background-background-2);
    color: var(--dark-primary-blue-1);
} */

.dark nav.child-menu a {
    color: var(--dark-text-text-2);
}

.dark nav.child-menu {
    background: var(--dark-background-background-1);
}

/* Filtros - Dashboard */
.dark .filters{
    color: var(--dark-text-text-1);
}

/* FOOTER */
.dark
.company-branch-link {
    font-weight: 700;
    text-decoration: none;
    color: #a2a2a2;
}

/* Components - Componentes */
/* Bold Select */
.dark
.bold-select{
    background: var(--dark-background-background-1);
    border-radius: 10px !important;
    color: var(--dark-text-text-2);
}

.dark .bold-select > svg {
    color: white;
}

/* dropdowns */
.dark ul.dropdown-menu.item-table-options{
    background: var(--dark-background-background-2);
}
.dark li.btn.item-table-action{
    color: var(--dark-text-text-1);
}

/* INPUTS */
.dark
.input-icon {
    position: relative;
    background: var(--dark-background-background-1);
    width: 100%;
}
/* input search */
.dark
.input-icon.input-search .clear{
    color: #df0606;
    right: 30px;
    font-size: 16px;
    top: 10px;
    cursor: pointer;
    height: 300px;
}

.dark
.input-icon input {
    width: 100%;
    margin-right: 15px;
    background: var(--dark-background-background-1);
    border: 1px solid var(--dark-neutral-gray-2);
    border-radius: 8px;
    height: 40px;
    padding-right: 30px;
    padding-left: 10px;
    font-size: 14px;
}

.dark
.input-icon span {
    position: absolute;
    top: 6px;
    right: 5px;
    color: #a5a5a5;
    text-shadow: none;
}

.dark textarea {
    color: var(--dark-text-text-2) !important;
}

/* ##########
Input checkbox
##############*/
.dark .MuiCheckbox-root:not(.Mui-checked) svg {
    color: var(--dark-text-text-2);
}

/*################
    BUTTONS
################## */
.dark .btn-light{
    background: var(--dark-background-background-2) !important;
    color: var(--dark-text-text-1);
    border: 1px solid var(--dark-elements-elements-3) !important;
}

/*################
    TABLE
################## */

.dark .counter-data-table{
    color: var(--dark-text-text-2);
}

.dark button.btn-table-actions {
    color: var(--dark-text-text-2);
    text-shadow: none;
}

.dark .rdt_TableHead, .dark .rdt_TableHeadRow{
    background-color: var(--dark-background-background-1) !important;
}

.dark .rdt_TableCol {
    color: var(--dark-text-text-2);
    border-right: 1px solid var(--dark-elements-elements-5);
}

.dark .rdt_TableCell {
    color: var(--dark-text-text-2);
    border-right: 1px solid var(--dark-elements-elements-5);
}

.dark .rdt_TableBody > div:nth-child(even) {
    background: var(--dark-background-background-1);
}

.dark .rdt_TableBody > div:nth-child(odd) {
    background: var(--dark-background-background-2);
}

.dark nav.rdt_Pagination {
    color: var(--dark-text-text-2);
}

.dark .rdt_Pagination button svg {
    fill: #d5dce5;
}

.dark .sc-ezOQGI.hoQsHK button svg{
    color: var(--dark-text-text-2);
    fill: var(--dark-text-text-2);
}

.dark .item-table-action span{
    color: var(--dark-text-text-1);
}