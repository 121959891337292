.card-listPayment {
    border-radius: 10px;
    border: 1px solid var(--light-elements-border-panels-1);
    padding: 15px;
    display: flex;
    align-items: center;
    background-color: var(--light-background-background-1);
}

.dark .card-listPayment {
    border: 1px solid var(--dark-elements-border-panels-1);
    background-color: var(--dark-background-background-1);
}

.card-listPayment.default {
    background-color: var(--light-primary-blue-1);
}

.dark .card-listPayment.default {
    background-color: var(--dark-primary-blue-1);
}

.card-textPayment {
    flex: 1;
    padding-left: 10px;
    color: var(--light-text-text-1);
}

.dark .card-textPayment {
    color: var(--dark-text-text-1);
}

.card-listPayment.default .card-textPayment {
    color: var(--light-text-text-3);
}

.dark .card-listPayment.default .card-textPayment {
    color: var(--dark-text-text-3);
}

.button-iconTrash {
    border: 1px solid var(--light-elements-elements-3);
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--light-background-background-1);
    width: 25px;
    height: 25px;
}

.dark .button-iconTrash {
    color: var(--dark-text-text-1);
    border: 1px solid var(--dark-elements-elements-3);
    background-color: var(--dark-background-background-1);
}

.button-checkPayment {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    border: 1px solid var(--light-elements-border-panels-1);
    border-radius: 3px;
    padding: 5px;
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--light-background-background-1);
}

.dark .button-checkPayment {
    border: 1px solid var(--dark-elements-elements-3);
    background-color: var(--dark-background-background-1);
}

.card-listPayment.default .button-checkPayment {
    background-color: var(--light-primary-blue-1);
}

.dark .card-listPayment.default .button-checkPayment {
    background-color: var(--dark-primary-blue-1);
}

.colorcheck{
    color: var(--dark-text-text-1);
}
