/* Estilos Definitivos del Framework bold */

.no-view {
    display: none !important;
}
.absolute{
    position: absolute;
}
.np-row{
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.ph-20 {
    padding-left: 20px;
    padding-right: 20px;
}

/* Buttons */
.btn-primary{
    background: rgba(238, 168, 55, 0.8);
    color: #000000;
    border:0px;
    font-weight: 600;
}
.btn-table-options{
    border-radius: 30px;
    padding: 5px 25px;
    font-size: 1em;
}
.btn-table-options-schedule{
    border-radius: 30px;
    padding: 5px 10px;
    font-size: 14px;
}
.btn-light.btn-table-options:active{
    background-color: var(--light-elements-elements-5) !important;
}

.btn-primary:hover, .btn-light:hover{
    background:  rgba(255, 175, 46, 0.6);
    color: #000000;
}
.item-table-action:hover{
    background-color: var(--light-primary-yellow-5);
}


/* lenguajes drop down */
.language-login {
    position: fixed;
    right: 20px;
    top: 10px;
}
/* Formularios */
.text-input-error{
    color: #E02F51;
    font-size: 12px;
}

/* ################################################################################################ */
/* -------------------------------Arriba esta todo muy definido :) abajo puede eliminarse futuramente--------------------------------------- */




/* .menu-btn-toggle {
    display: none;
    position: absolute;
    right: 15px;
    top: 40px;
    color: white;
    background: #282828;
    width: 36px;
    height: 36px;
    padding-top: 6px;
    padding-left: 4px;
    border-radius: 50%;
    text-shadow: 2px 1px 4px #b9b9b9;
} */
.menu-btn-toggle {
    display: none;
    position: absolute;
    right: 15px;
    top: 40px;
    font-size: 30px;
    color: #c7c7c7;
    background: #282828;
    width: 36px;
    font-weight: 200;
    height: 36px;
    line-height: 34px;
    text-align: center;
    border-radius: 50%;
    text-shadow: 2px 1px 4px #838383;
}

span.MuiSkeleton-root {
    margin-bottom: 10px;
}

.aside ul {
    list-style: none;
    padding: 0px;
}



.loader {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dedede96;
    z-index: 10;
}

.bold-container.responsive {
    margin-left: 75px;
    width: calc(100% - 80px);
}

.logo {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.106);
}

/* ASIDE */



li.menu-item>a:hover {
    background: #565656;
}

li.menu-item:hover span.material-symbols-outlined{
    color: var(--light-primary-blue-1);
}



/* MENU */
/*Section filters (por ahora en barberos)*/
.filter-elements{
    display: flex;
    justify-content: space-between;
}

.Mui-disabled{
    -webkit-text-fill-color: var(--light-text-text-2) !important;
}

/* input phone */
.bold-input-phone .MuiOutlinedInput-root input{
/* .MuiOutlinedInput-root input{ */
    padding-left: 70px;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink{
    padding-left: 0px;
    transition: 0.3s linear all;
}

/* FOOTER */
.footer-branch {
    font-size: 13px;
    margin: 0px 10px;
}

ul.important-links {
    display: flex;
    list-style: none;
    padding-left: 0px;
    margin-bottom: 8px;
}

ul.important-links li {
    padding: 0px 5px;
}

ul.important-links a {
    text-decoration: none;
}

.company{
    font-size:13px;
}


/* TABLE */
.container-table header {
    display: none;
}
.bold-header-table .section-events {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.section-events .input-icon {
    width: 70%;
    margin-right: 15px;
}

button.btn-table-actions span {
    font-size: 20px;
}

dt {
    font-weight: 600;
    color: #535566;
    font-size: 15px;
}
dd {
    margin-bottom: 0px;
    margin-left: 0;
    font-weight: 200;
    color: #9a98b3;
    word-wrap: break-word;
}

/* BUTTOMS */

button.dropdown-item.selected {
    font-weight: 700;
}
.bold-link{
    border: 0px;
    background: transparent;
    padding: 5px 10px;
    color: var(--light-text-text-2)
}

.bold-btn {
    border: 0px;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 22px;
    vertical-align: middle;
    display: inline-block;
    background: transparent;
    margin: 5px 10px;
}

button.bold-btn-dark {
    color: white;
    background: linear-gradient(0deg, black, #000000c4);
    border-radius: 8px;
}

button.bold-btn-light {
    color: #514f4f;
    background: linear-gradient(195deg, rgb(235, 239, 244), rgb(206, 212, 218));
    border-radius: 8px;
}


/* Forms */

.section-image-view {
    text-align: center;
    width: 150px;
    max-width: 150px;
    max-height: 150px;
    min-height: 125px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    border: 2px solid grey;
}
.bold-container-content > .row {
    max-width: 960px;
    margin-left: auto !important;
    margin-right: auto !important;
}

.section-forms {
    background: white;
    padding: 30px 20px;
    border-radius: 15px;
    margin: 10px;
    box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
}

.title-section-forms {
    display: block;
    text-align: center;
    color: rgb(52, 71, 103);
    font-size: 28px;
    font-weight: 600;
    margin: 0px 0px 20px;
}

.form-title {
    font-size: 20px;
    margin: 30px 0px 8px;
    color: #344767;
    font-weight: 400;
    padding: 0px 20px;
}
span.divisor {
    width: 80%;
    margin: 10px auto;
    display: block;
    height: 1px;
    background: #e7e7e7;
}

/* radio group */
.MuiFormGroup-root {
    display: block !important;
    padding: 10px 15px;
}
.MuiFormGroup-root label {
    padding-right: 15px;
}
.MuiFormGroup-root label span:nth-child(2) {
    font-size: 15px;
    font-weight: 300;
}

/* checkbox */
label.MuiFormControlLabel-root span:nth-child(2) {
    font-weight: 300;
    color: #636363;
}





/* Widgets */
.widget {
    background: #fff;
    border-radius: 20px;
    padding: 10px 15px 20px;
    margin: 0px 15px 40px;
    position: relative;

}

.widget hr {
    clear: both;
    color: rgb(174, 174, 174);
}

.icon-widget-1 {
    background: linear-gradient(0deg, rgb(16 66 121), rgb(34 134 244));
}

.icon-widget-2 {
    background: linear-gradient(0deg, rgb(14 117 18), rgb(40 227 125));
}

.icon-widget-3 {
    background: linear-gradient(0deg, rgb(160 6 6), rgb(255 36 90));
}

.icon-widget-4 {
    background: linear-gradient(0deg, rgb(73, 73, 171), rgb(116, 116, 222));
}

.icon-widget {
    width: 60px;
    height: 60px;
    text-align: center;
    position: absolute;
    top: -16px;
    left: 25px;
    border-radius: 8px;
}

.icon-widget span {
    color: white;
    width: 100%;
    text-align: center;
    line-height: 60px;
    font-size: 30px;
}

.title-widget {
    display: block;
    float: right;
    font-weight: 200;
    color: rgb(154, 154, 154);
    padding: 0px 10px 10px;
}

.title-widget-complement {
    color: rgb(0, 0, 0);
    font-weight: 600;
}

.compare-widget {
    font-weight: 300;
}


/* MUI library */
/* borde inferior inputs */
.css-1dbuez4-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,
.css-whebh7-MuiInputBase-root-MuiInput-root:before,
.css-1np443e-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 1px solid #c1c0c06b !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #1870c8 !important;
    font-weight: 500 !important;
}

.MuiPaper-root {
    border-radius: 10px !important;
    margin-top: 2px;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    margin: 0px 6px !important;
    border-radius: 7px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: rgb(240 244 249) !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    border-radius: 7px;
    margin: 0px 6px;
    background-color: rgb(29 130 230 / 27%) !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    color: var(--light-text-text-2);
    font-weight: 200;
}

.dark .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    color: var(--dark-text-text-2);
}

.MuiFormControl-root.MuiTextField-root label {
    font-weight: 200;
}

/*USERS FORM*/
.circle-name {
    background: #2b2b2f;
    color: white;
    text-align: center;
    height: 3em;
    width: 3em;
    border-radius: 50%;
    font-size: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}
.form-username {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.form-username label {
    margin: 20px 0;
    color: #61616a;
}

/*Estilos para tarjeta de serviceTeam*/

.containerCard{
    width: 70%;
    display: flex;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    flex-direction: row;
    align-items: center;
    height: 60px;
    border-width: 1px;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    z-index: 2;
    margin-bottom: 10px;
    background-color: white;
    justify-content: space-between;
}

.alignCard{
    display: flex;
}

.iconCard{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-width: 1px;
    border-radius: 25px;
    border: solid 1px gray;
    width: 28px;
    height: 28px;
    z-index: 1;
}

.backgroundIcon{
    background-color: #F0B669;
}

.subContainer-card{
    width: 70%;
    display: none;
    background-color: '#eeeeee';
    margin-left: 10px;
    margin-right: 10px;
    border-width: 1px;
    border: solid 1px #d8d8d8;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 10px;
    position: relative;
    top: -15px;
    overflow: hidden;
    padding-bottom: 15px;
}

.oculto {
    display: block;
  }

.colorCard{
    background-color: #1870c8;
}

.alignCardLeft{
    display: flex;
    margin-left: 15px;
}

.containerCardFluid{
    width: 100%;
    display: flex;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    flex-direction: row;
    align-items: center;
    height: 60px;
    border-width: 1px;
    border: 1px solid #d8d8d8;
    background-color: #fff;
    border-radius: 10px;
    z-index: 2;
    margin-bottom: 10px;
    justify-content: space-between;
}

.subContainer-card-fluid{
    /* width: 95%; */
    display: flex;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    flex-direction: row;
    align-items: center;
    height: 60px;
    border-width: 1px;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    z-index: 2;
    margin-bottom: 10px;
    background-color: white;
    justify-content: space-between;
}

.subContainer-card-fluid-parent{
    width: 100%;
    display: none;
    background-color: '#eeeeee';
    margin-left: 10px;
    margin-right: 10px;
    border-width: 1px;
    border: solid 1px #d8d8d8;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 10px;
    position: relative;
    top: -15px;
    overflow: hidden;
    padding-bottom: 15px;
}
.colorCardServices{
    background-color: #1870c8 !important;
}

.dropdrown-services-categories{
    inset: -61px 22px auto auto !important;
    min-width: 183px !important;
}

.title-services{
    font-size: 14px;
    font-weight:600;
    color: #000000;
}

.content-services-title{
    position: absolute;
    top: 12px;
    left: 77px;
}

.time-services{
    font-size: 13px;
    color: #000000; 
}

.content-price-services{
    position: absolute;
    top: 38px;
    right: -14px;
}

.price-services{
    font-size: 13px;
    color: #000000; 
}
.content-time-services{
    margin-left: 65px;
    margin-top: 13px;
}

.content-price-services{
    right: 23px;
    top: 57px;
}

.iconCardServices{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-width: 1px;
    border-radius: 25px;
    border: solid 1px gray;
    width: 28px;
    height: 28px;
    z-index: 1;
    margin-top: 12px;
    margin-left: 17px;
    position: absolute;
    right: 10px;
}

.alignCardLeftServicesImg{
    display: flex;
    margin-left: 15px;
    margin-top: 10px;
}

.alignCardLeftServicesName{
    display: flex;
    margin-left: -10px;
    margin-top: 8px;
}

.iconCardChevron{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-width: 1px;
    border-radius: 25px;
    border: solid 1px gray;
    width: 28px;
    height: 28px;
    z-index: 1;
    position: absolute;
    right: 11px;
    bottom: 14px;
}

.iconCardMore{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-width: 1px;
    border-radius: 25px;
    border: solid 1px gray;
    width: 28px;
    height: 28px;
    z-index: 1;
    position: absolute;
    right: 56px;
    bottom: 14px;
}
.info-detail{
    background-color: var(--light-background-background-1);
}
.modalfooter-buttons{
    display: flex;
    gap: 10px;
}
.dark.info-detail{
    background-color: var(--dark-background-background-1);
}