.apointmentCard {
    height: 110px;
    background-color: var(--light-background-background-1);
    border-radius: 10px;
    border: solid 1px var(--light-elements-border-panels-1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.dark .apointmentCard{
    background-color: var(--dark-background-background-1);
    border-color: var(--dark-elements-border-panels-1);
}

.customerViewButton {
    height: 20%;
    border: var(--light-elements-elements-1) solid 1px;
    border-radius: 12px;
    overflow: hidden;
    background-color: var(--light-primary-yellow-1)!important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 5px;
}

.dark .customerViewButton{
    border: var(--dark-elements-elements-1) solid 1px;
    background-color: var(--dark-primary-yellow-1);
}

.appoitmentCardImage {
    display: flex;
    background-color: var(--light-primary-blue-1);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border: solid 1px var(--dark-elements-border-panels-1);
    text-align: center;
    align-content: center;
    color: var(--light-background-background-1);
}

.appoitmentCardImageContainer{
    width: 55px;
    justify-content: center;
    align-items: center;
}

.status-container {
    line-height: 15px;
    text-align: center;
    width: 70px;
    height: 20px;
    border-radius: 5px;
    color: var(--light-text-text-6);
    font-size: 12px;
    border: 2px solid var(--light-elements-elements-1);
    font-weight: 500;
}

.dark .status-container{
    color: var(--dark-text-text-6);
    border: 2px solid var(--dark-elements-elements-1);
}

.card-title{
    color: var(--light-text-text-1);
}

.dark .card-title{
    color: var(--dark-text-text-1);
}

.text-status {
    line-height: 0.9;
    color: var(--light-text-text-6);
}

.dark .text-status {
    color: var(--dark-text-text-6);
}

.block-text {
    line-height: 1.2;
}

.container-text-appointment-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    padding: 0px 10px;
}

.container-box-appointment-card {
    width: 70%;
    display: flex;
    height: 100%;
}

.button-appointment-card {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
}