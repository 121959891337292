.tablePermissionContainer{
    background: var(--light-background-background-1);
    border-radius: 10px;
}
.optionsPermission{
    display: flex;
    align-items: center;
}
.checkBoxPermission{
    padding: 0;
}

.textOption{
    color: var(--light-text-text-2);
}
.rowPermissionCheck{
    max-width: 50px;
    width: 50px; 
}

.sizeTrName{
    width: 150px;
}

.sizeTr{
    width: 60px;
}


.body-permission tr:nth-child(odd){
    background-color: var(--light-background-background-2);
}
.dark .body-permission tr:nth-child(odd){
    background-color: var(--dark-background-background-2);
}

td:not(:last-child), th:not(:last-child) {
    border-right: 1px solid #ccc;
}

td, th {
    border-bottom: none;
    border-collapse: collapse;
}

.dark .tablePermissionContainer{
    background: var(--dark-background-background-1);
}

.dark .textOption{
    color: var(--dark-text-text-2);
}

.dark .rowColor tbody tr:nth-of-type(odd) {
    background-color: var(--dark-background-background-2);
}
