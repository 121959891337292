.round-image-container {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: var(--light-primary-blue-2);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.round-image-container i{
    font-size: 1.8rem;
    color: var(--light-text-text-5);
}

.round-image-container .round-image{
    width:100%;
    height: 100%;
    object-fit: cover;
}

/*DARK MODE*/
.dark .round-image-container{
    background-color: var(--dark-primary-blue-2);
}
.dark .round-image-container i{
    color: var(--dark-text-text-5);
}