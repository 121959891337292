/*  ################
    Buttons
    ################ 
*/

.buttonOutline{
    border: 1px solid var(--light-elements-elements-1);
    background: transparent;
    display: block;
    width: 100%;
    padding: 8px 7px;
    border-radius: 10px;
    color: var(--light-text-text-1);
    overflow: hidden;
}
.buttonPrimary{
    background: var(--light-primary-yellow-1);
    font-weight: 600;
    width: 100%;
    padding: 8px 7px;
    border: 0px;
    border-radius: 10px;
    overflow: hidden;
}
.buttonPrimary:active{
    background: var(--light-primary-yellow-1) !important;
    color: var(--light-text-text-1);
}
.buttonPrimary:hover{
    background-color: #fbc987;
    color: var(--light-text-text-1);
    cursor: pointer;
}
.buttonPrimary.disabled{
    background-color: #eec186;
}
.buttonPrimary.disabled:hover{
    background-color: #eec186;
    color: var(--light-text-text-1);
}

.buttonLink, .buttonLink:active{
    background-color: transparent !important;
    border: none;
    color: var(--light-text-text-1);
    width: fit-content;
}
.buttonLink i{
    padding: 0px 5px;
}

.btn-inline{
    width: fit-content !important;
}


/*  ################
    Buttons dark
    ################ 
*/

html.dark 
.buttonOutline{
    border: 1px solid var(--dark-elements-elements-1);
    background: transparent;
    display: block;
    width: 100%;
    padding: 8px 10px;
    border-radius: 10px;
    margin: 4px 0px;
    color: var(--dark-text-text-1);
}

html.dark 
.buttonPrimary{
    background: var(--dark-primary-yellow-1);
    font-weight: 600;
    width: 100%;
    margin: 4px 0px;
    padding: 8px 7px;
    border: 0px;
    border-radius: 10px;
    color: var(--dark-text-text-6);
}
html.dark 
.buttonPrimary:active{
    background: var(--dark-primary-yellow-1) !important;
    color: var(--dark-text-text-6);
}
html.dark 
.buttonPrimary:hover{
    background-color: #fbc987;
    color: var(--dark-text-text-6);
}

html.dark 
.buttonPrimary.disabled{
    background-color: #eec186;
    color: var(--dark-text-text-6);
}
html.dark 
.buttonPrimary.disabled:hover{
    background-color: #eec186;
    color: var(--dark-text-text-6);
}

html.dark 
.buttonLink, .buttonLink:active{
    background-color: transparent !important;
    border: none;
    color: var(--dark-text-text-1);
}
