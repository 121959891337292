.text-view input, 
.text-view textarea, 
.text-view .MuiOutlinedInput-root{
    background: var(--light-background-background-1) ;
    border-radius: 10px;
}
.dark .text-view input, 
.dark .text-view textarea, 
.dark .text-view .MuiOutlinedInput-root{
    background: var(--dark-background-background-1);
}