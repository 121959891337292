.container-transaction-bill {
    display: flex;
    justify-content: center;
    align-items: center;
}
.container-details-transaction-bill{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    border: 1px solid var(--light-elements-border-panels-1);
    border-radius: 10px;
}
.dark .container-details-transaction-bill{
    border: 1px solid var(--dark-elements-border-panels-1);
}
.background-transaction-bill{
    background-color: var(--light-background-background-1);
}
.dark .background-transaction-bill {
    background-color: var(--dark-background-background-1);
}