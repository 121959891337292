
.container-header-screen{
    width: 100%;
    display: block;
}
.container-header-screen .section-title-screen{
    width: 100%
}

.container-header-screen .title-screen{
    color: var(--light-text-text-1);
}

.icon-red{
    color: var(--light-status-error);
}

/* #######################
    MODO OSCURO
##########################*/
.dark .container-header-screen .title-screen{
    color: var(--dark-text-text-1);
}

.dark .container-header-screen .fa-arrow-left{
    color: var(--dark-text-text-1);
}
.dark .icon-red{
    color: var(--dark-status-error);
}
