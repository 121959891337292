.cardBusinessContainer{
    background: var(--light-background-background-1);
}

.textBusinessColor{
    color: var(--light-text-text-1);
}

/* MODO OSCURO */

.dark .cardBusinessContainer{
    background: var(--dark-background-background-1);
}

.dark .textBusinessColor{
    color: var(--dark-text-text-1);
}