@import "./../../../assets/css-generated/variables.css";

.centralmodal-overlay{
    width: 100%;
    height: 100vh;
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(145 145 145 / 29%);
}
.centralmodal-container{
    position: fixed;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--light-background-background-1);
    min-width: 300px;
    width: 35%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}
.centralmodal-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px;
    color: var(--light-text-text-1);
    border-bottom: 1px solid var(--light-elements-elements-5);
    color: var(--light-text-text-1);
}
.centralmodal-title{
    font-size: 1.2em;
    font-weight: 700;
    margin: 0;
}
.centralmodal-close-button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    transition: all 0.5s;
}
.centralmodal-close-button i{
    color: var(--light-text-text-1);
}
.dark .centralmodal-close-button i{
    color: var(--dark-text-text-1);
}
.centralmodal-close-button:hover{
    background-color: var(--light-primary-yellow-4);
}
.dark .centralmodal-close-button:hover i{
    color: var(--dark-text-text-3);
}
.centralmodal-body{
    padding: 15px;
    display: flex;
    flex-direction: column;
    color: var(--light-text-text-2);
}
.centralmodal-footer{
    display: flex;
    justify-content: center;
    bottom: 0;
    width: 100%;
    padding: 15px; 
    border-top: 1px solid var(--light-elements-elements-5)
}
.icon-xmark {
    color: var(--light-text-text-1);
}
.icon-xmark:hover{
    color: var(--light-text-text-6);
}

/* dark */
.dark .centralmodal-container{
    background: var(--dark-background-background-1);
}
.dark .centralmodal-header{
    border-bottom: 1px solid var(--dark-elements-elements-5);
    color: var(--dark-text-text-1);

}
.dark .centralmodal-close-button:hover{
    background-color: var(--dark-primary-yellow-4);
}
.dark .centralmodal-body{
    color: var(--dark-text-text-2);
}
.dark .centralmodal-footer{
    border-top: 1px solid var(--dark-elements-elements-5)
}

.dark .icon-xmark {
    color: var(--dark-text-text-1);
}

.dark .icon-xmark:hover{
    color: var(--dark-text-text-6);
}