.timeline {
    background-color: var(--light-background-background-1);
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 20px 0;
    padding: 20px 15px;
}
.timeline-item-first {
    align-items: flex-start !important;
}

.dark .timeline {
    background-color: var(--dark-background-background-1);
}

.timeline-item {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    position: relative;
}
.timeline-item:nth-child(0) {
}

.timeline-item.last-item {
    margin-bottom: 0;
}

.timeline-content {
    font-size: small;
    margin-left: 15px;
    color: var(--light-text-text-1)
}

.dark .timeline-content {
    color: var(--dark-text-text-1)
}

.status-point {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    background-color: var(--light-neutral-gray-3);
    border-radius: 50%;
    position: relative;
}

.dark .status-point {
    background-color: var(--dark-neutral-gray-3);
}

.status-point.active {
    background-color: var(--light-primary-blue-1);
    border: 2px solid var(--light-background-background-1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.dark .status-point.active {
    background-color: var(--dark-primary-blue-1);
    border: 2px solid var(--dark-background-background-1);
    box-shadow: 0 2px 4px rgba(181, 181, 181, 0.2);
}

.timeline::before {
    content: '';
    position: absolute;
    left: 26px;
    top: 60px;
    bottom: 0;
    width: 2px;
    background-color: var(--dark-neutral-gray-3);
    height: 66%;
}

.timeline-item:last-child .timeline-content {
    margin-bottom: 0;
}

.tittleTimeLine{
    color: var(--light-text-text-1);
    font-weight: 600;
    font-size: larger;
}

.dark .tittleTimeLine{
    color: var(--dark-text-text-1);
}

.state-name{
    color: var(--light-text-text-1);
    font-weight: 500;
}

.dark .state-name{
    color: var(--dark-text-text-1);
}