.multifield-view {
    background-color: var(--light-background-background-1);
    border-radius: 10px;
    position: relative;
    min-height: 100px;
    margin-bottom: 12px;
    box-sizing: border-box;
}
.label-multifield{
    display: block;
    position: absolute;
    top:-8px;
    padding: 0px 12px;
    color: var(--light-text-text-2);
}

.multifield-content{
    height: 100px;
    padding: 10px 0px 0px;
    max-height: 200px;
    min-height: 100px;
    flex: 1 auto;
    overflow: hidden;
    word-wrap: break-word;
    resize: vertical;
    overflow: hidden;
}

.value-multifield{
    height: 100%;
    max-height: 200px;
    width: calc(100% + 20px);
    font-size: 18px;
    display: block;
    color: var(--light-text-text-2);
    overflow: auto;
    padding: 10px;
}
/* #####################
    MODO OSCURO
######################## */
.dark .multifield-view{
    background-color: var(--dark-background-background-1);
}

.dark .label-multifield{
    color: var(--dark-text-text-2);
}

.dark .value-multifield{
    color: var(--dark-text-text-2);
}