
.container-bold-upload-images{
    width: 75px;
    height: 75px;
    display: block;
    position: relative;
}

.container-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    border-radius: 50%;
    background-position: center;
}

.container-bold-upload-section-upload {
    display: flex;
    width: 75px;
    height: 75px;
    justify-content: center;
    align-items: center;
    background: var(--light-elements-border-panels-2);
    border-radius: 50%;
    cursor: pointer;
}

.bold-upload-section-upload-icon {
    align-items: center;
    font-size: 35px;
    color: var(--light-text-text-3);
    font-weight: 300;
}

.camera-iconUpload {
    cursor: pointer;
    position: absolute;
    bottom: -4px;
    right: -5px;
    padding: 5px;
    font-size: 16px;
    border-radius: 50%;
    background: var(--light-background-background-1);
    border: 1px solid var(--light-elements-elements-1);
    color: var(--light-text-text-1);
}
/* ############################
    TEMA OSCURO
############################ */
.dark .container-bold-upload-section-upload {
    background: var(--dark-elements-border-panels-2);
}

.dark .bold-upload-section-upload-icon {
    color: var(--dark-text-text-3);
}

.dark .camera-iconUpload {
    background: var(--dark-background-background-1);
    border: 1px solid var(--dark-elements-elements-1);
    color: var(--dark-text-text-1);
}