@import url("./../../../assets/css-generated/variables.css");
@import url("./css/buttons.css");

.app {
    margin-top: 10px;
    width: 100%;
    padding: 10px 20px;
    display: block;
}

html {
    background: var(--light-background-background-2);
    transition: .3s linear all;
}


html.light {
    transition: .3s linear all;
}

html.dark {
    transition: .3s linear all;
}

/*  ################
    Dark MODE
    ################ */
/* .light li.menu-item a > div svg path{
    fill: red;
}
.dark li.menu-item a > div svg path{
    fill: var(--light-text-text-3);
}

html.light {
    background: #f0f2f5;
    transition: .3s linear all;
}

html.dark  {
    background: #333333;
    transition: .3s linear all;
} */
/*  ################
    FORMULARIOS
    ################ */
/* .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 10px !important;
} */
/* .MuiInputBase-root {
    margin-bottom: 15px !important;
} */
.Mui-checked{
    color: var(--light-primary-blue-2)!important;
}
.dark .Mui-checked{
    color: var(--dark-primary-blue-2)!important;
}

.MuiFormControl-root {
    width: 100%;
    margin-bottom: 15px !important; /*No usar, colocar margen en contenedor padre*/
}

.Mui-disabled fieldset {
    border: inherit !important;
}

.css-wjsjww-MuiChip-label {
    font-weight: 600;
    font-size: 11px;
}

.css-1fj5pxn-MuiChip-root {
    width: 100%;
}

.eVPPvF {
    padding-left: 13px !important;
    padding-right: 13px !important;
}

/* .text-view {
    background-color: var(--light-background-background-1);
} */

.css-1fj5pxn-MuiChip-root {
    border-radius: 4px !important;
}

/* Fonts */
.fw-600{
    font-weight: 600;
}

.btn.btn-secondary-green {
    background: var(--light-secondary-green);
    color: var(--light-text-text-6);
    font-weight: bold;
}

.btn-group-main {
    margin:0;
    padding: 0;
    background: var(--light-background-background-1);
}

.btn-border-none {
    border: none !important;
}

/*  ################
    Dropdowns
    ################ */
.btn.btn-primary-yellow-1 {
    background: var(--light-primary-yellow-1);
}

.btn.btn-primary-blue-1 {
    background: var(--light-primary-blue-1);
}

.actions-link {
    color: var(--light-text-text-2);
    font-weight: 400;
    font-size: 12px;
    margin: 0px;
}

.bold-container {
    display: block;
    float: left;
    margin-left: 250px;
    min-height: 100vh;
    max-height: 100vh; /* se agrega esta y el overflow para que se mantenga el scroll en formularios */
    width: calc(100% - 250px);
    position: relative;
    transition: all .3s linear;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
}
.info-entity{
    max-width: 1200px;
    margin: auto
}

.bold-container.full {
    margin-left: 70px;
    width: calc(100% - 70px);
    transition: all .3s linear;
}

.brand-logo {
    position: sticky;
    top: 0px;
    width: 100%;
    display: block;
    z-index: 1;
}

/*################
    POLICIES
################## */

.text-box-policies div {
    overflow-y: auto;
    min-height: 300px;
    max-height: 360px;
    padding: 15px 10px;
}

.cke_chrome {
    border-radius: 10px;
    overflow: hidden;
}

/*################
    MENU
################## */
/* ul#menu-list {
    overflow: auto;
    display: block;
    height: 83vh;
} */
.shadow-aside {
    background: #76767645;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.shadow-aside .menu-btn-toggle {
    display: block;
}

ul#menu-list {
    overflow: auto;
    display: block;
    height: 90vh;
    margin-top: 44px;
}

/*  ################
    SECTION PAGE
    ################ */
.iconSize{
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 55px;
    align-items: center;
    justify-content: center;
    overflow: auto;
    border-radius: 50%;
}


/*################
    Actions
################## */
a.dropdown-item.actions {
    font-size: 14px;
    font-weight: 300;
}

a.dropdown-item.actions svg {
    margin-right: 12px;
}

/*################
    Filters
################## */
.filters {
    margin-bottom: 20px;
}

/*################
    TABLE
################## */
img.bold-image-table {
    border-radius: 50%;
    border: none;
    margin: 5px 0px;
    width: 30px;
    height: 30px;
    object-fit: cover;
}

/* Image with name in tables, applied in services, team */
.barber-image-with-name{
    display: flex;
    align-items: center;
    padding: 5px 0;
}
.barber-image-with-name > p {
    margin: 0;
    text-wrap: wrap;
}
.barber-image-table {
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;
    margin-right: 5px;
    border-radius: 50%;
    border: 1px solid var(--light-neutral-gray-3);
    background: var(--light-primary-blue-1);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light-text-text-3);
    font-size: 16px;
    text-transform: uppercase;
}
img.barber-image-inside {
    width: 100%;
    height: 100%;
}
/* Avatar */
.MuiAvatar-circular {
    font-size: 14px !important;
    width: 28px !important;
    height: 28px !important;
    line-height: 28px !important;
}

.color_avatar {
    background-color: var(--light-primary-blue-1) !important;
    color:var(--light-text-text-3);
}
.dark .color_avatar{
    color:var(--dark-text-text-3);
    background-color: var(--dark-primary-blue-1) !important;
}

.optionEnabled{
    color: var(--light-text-text-3) !important;
}

.dark .optionEnabled{
    color: var(--dark-text-text-3)!important;
}

.bold-header-table h3 {
    color: #000000;
    font-weight: 500;
    margin-left: 32px;
}

.bold-header-table {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.rdt_Table {
    border: 1px solid var(--light-elements-elements-5);
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--light-background-background-1) !important;
}
.dark .rdt_Table {
    border: 1px solid var(--dark-elements-elements-5);
    background-color: #0f0f10 !important;
}

.rdt_TableCell{
    color: #37465A;
    font-weight: 500;
}

.rdt_TableHeadRow {
    border: none !important ; 
}

.rdt_TableBody>div {
    border: 0px;
}

.rdt_TableBody>div:nth-child(odd) {
    background: var(--light-background-background-2);
}

.material-symbols-outlined.active {
    color: #264c99;
}

.material-symbols-outlined.inactive {
    color: #ff9b15;
}

/* DROPDOWNS TABLES */
/* .Nfhbm{
    overflow-y: clip !important;
    overflow-x: clip !important;
}
.drowpdown-table{
    position: absolute;
    top: 4px;
    z-index: 100;
} */

/*
Filtro de tabla
*/

.container-search .input-search{
    flex: 6 0;
}
.container-search .buttonPrimary {
    margin: 0px 0px 0px 5px !important;
    flex: 1 1 0;
    min-width: 65px !important;
}
.inputText-icon{
    color: var(--light-text-text-1);
}
.dark .inputText-icon{
    color: var(--dark-text-text-1);
}

 /* 
 ************************
 Tabla - TABLE
 Estilos necesarios para que los drop downs se vean correctamente en data table - tabla 
 ************************
 */
.bold-container-table {
    overflow: visible;
}
.bold-container-table > div:nth-child(1), .bold-container-table .sc-hzhKNl, .sc-hzhKNl{
    min-height: 260px !important;
    overflow-y: visible !important;
    overflow-x: auto;
    scrollbar-width: thin;
}

.bold-container-table .sc-hzhKNl, .sc-hzhKNl{
    overflow: visible;
    scrollbar-width: thin;
}

.rdt_Table{
    overflow: visible !important;
    border-radius: 2px !important;
}
.rdt_TableCol:last-child, .rdt_TableCell:last-child{
    border: 0px !important;
}

/* estilo usado cuando no hay resultado en la tabla */
.dark .rdt_Table > div:nth-child(2) {
    background: var(--dark-background-background-1);
    color: var(--dark-text-text-1);
}

/*  ************************
 / Tabla - TABLE
 Estilos necesarios para que los drop downs se vean correctamente en data table - tabla 
 ************************
*/

/* empty state */
.empty-state-image {
    max-width: 200px;
    width: 100%;
}

.empty-state-text {
    color: var(--light-text-text-1);
    max-width: 400px;
    color: var(--light-text-text-1);
}

.dark .empty-state-text {
    color: var(--dark-text-text-1);
}

.dark .empty-state-text {
    color: var(--dark-text-text-1);
}

.title-empty{
    color: var(--light-text-text-1);
    font-weight: bold;
    font-size: 30px;
}

.dark .title-empty{
    color: var(--dark-text-text-1);
}

.fs-title-empty{
    font-size: 24px;
}

/*CLASE CUSTOMER TYPE*/
.clientTypeNew {
    font-size: 10;
    border-radius: 10%;
    background-color: #2ea71e;
    /* Cambia el color de fondo según tus preferencias */
    color: #fff;
    padding: 5%;
}

/*Formulario Editar cliente*/
.section-forms-edit {
    padding: 30px 20px;
    border-bottom: solid 1px var(--light-neutral-gray-3);
    margin: 10px;
}

/*Forms*/
.form-footer{
    border-top: 1px solid var(--light-elements-elements-1);
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-bottom: 15px;
    display: flex;
}

/*Tabs navigation*/
.tab-styles{
    overflow-x: auto;
}



@media(max-width: 768px) {
    .bold-container {
        margin-left: 0px;
        width: 100%;
        transition: all .3s linear;
    }

    .aside.hide {
        left: -265px;
        transition: all 0.3s linear;
    }

    .bold-container.full {
        margin-left: 0px;
        width: 100%;
    }
}

/*Panel Cliente*/
.ai-center {
    align-items: center;
}

/*Boton Fotos barberos */
.a-photos {
    border: 1px solid var(--dark-neutral-gray-3);
    border-radius: 6px;
    background-color: var(--light-background-background-1);
    align-items: center;
    padding: 0px 14px 0px 14px;
    font-weight: 500;
    height: 35px
}

/*Boton panel de barberos - NO USAR ESTILOS GENERICOS ATT: DANIEL */
/* .btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active
/*
:not(.btn-check)+.btn:active // comente este pedazo - daniel
*
{
    background-color: var(--light-primary-yellow-1) !important; /* NO CAMBIAR ESTA LINEA, USAR ESTILOS NO GENERICOS*
} */

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn.dropdown-toggle-split:first-child,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:nth-child(n+3),
.btn-group>:not(.btn-check)+.btn {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.btn-outline-primary {
    --bs-btn-color: #000000 !important;
}

/*columnas de las tablas*/
.column-border {
    border-right: 1px solid var(--dark-neutral-gray-2) !important;
    background-color: #3165CC !important;
}

.chips {
    display: flex;
    padding: 2px 5px 2px 5px;
    border-radius: 4px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    font-size: 11px;
}
.card {
    width: 75px;
    height: 75px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:#eee7e7;
    background-color:#4D689E ;
}
.card2 {
    align-items: flex-start;
    width: 350px;
    height: 80px;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 20px;
    justify-content: space-between;
    text-align: center;
    background-color:#fff ;
}

.new-client {
    background-color: var(--light-primary-yellow-1);
    color: var(--light-text-text-6);
}

.repeat-client {
    background-color: var(--light-status-success-2);
    color: var(--light-text-text-6);
}

.type-retention {
    background-color: var(--light-status-alternative-2);
    color: var(--light-text-text-2);
}

.pre-churn {
    background-color: var(--light-secondary-hard-orange);
    color: var(--light-text-text-6);
}

.type-recovered {
    background-color: var(--light-primary-blue-3);
    color: var(--light-text-text-3);
}

.type-vip {
    background-color: var(--light-secondary-black);
    color: var(--light-primary-yellow-3);
}

.type-block {
    background-color: var(--light-secondary-red);
    color: var(--light-text-text-3);
}

.type-coming{
    background-color: var(--light-primary-blue-1);
    color: var(--light-text-text-3);
}
.dark .type-coming{
    background-color: var(--dark-primary-blue-1);
    color: var(--dark-text-text-3);
}

.type-complete{
    background-color: var(--light-secondary-green);
    color: var(--light-text-text-6);
}
.dark .type-complete{
    background-color: var(--dark-secondary-green);
    color: var(--dark-text-text-6);
}

.type-missed{
    background-color: var(--light-secondary-red);
    color: var(--light-text-text-4);
}
.dark .type-missed{
    background-color: var(--dark-secondary-red);
    color: var(--dark-text-text-4);
}

.type-rescheduled{
    background-color: var(--light-primary-yellow-1);
    color: var(--light-text-text-6);
}
.dark .type-rescheduled{
    background-color: var(--dark-primary-yellow-1);
    color: var(--dark-text-text-6);
}

.type-cancel{
    background-color: var(--light-text-text-4);
    color: var(--light-text-text-6);
}
.dark .type-cancel{
    background-color: var(--dark-text-text-4);
    color: var(--dark-text-text-6);
}

.type-default {
    background-color: var(--light-status-info-2);
    color: var(--light-secondary-black);
}


/* .gmmAOl {
    border-right: 1px solid var(--dark-neutral-gray-2) !important;
}

.bQdYbR {
    border-right: 1px solid var(--dark-neutral-gray-2);
} */

.label-status {
    display: flex;
    padding: 2px 5px 2px 5px;
    border-radius: 4px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 11px;
}

.active-status {
    background-color: var(--light-status-success-2);
    color: var(--light-secondary-black);
}

.inactive-status {
    background-color: var(--light-status-error-2);
    color: var(--light-secondary-black);
}

.col-border {
    border-right: 1px solid var(--dark-neutral-gray-2);
}

.hPQIua, .MHRqX{
    flex: 0 0 35px !important;
    max-width: 35px !important;
    min-width: 35px !important;
}

.dwPlXY:not(:last-of-type) {
    border-bottom-style: none !important;
}

.container-uploads {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;
}
.formulario{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-left: 15px;
}
.formularios{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-left: 15px;
}
.section-bold-upload-files {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 130px;
    width: 100%;
    background: #f0f2f5;
    border: 3px #cacaca;
    border-radius: 10px;
    padding: 10px;
    overflow: auto;
}
.section-forms-view {
    padding: 30px 20px;
    border-radius: 15px;
    margin: 10px;
}
.column{
    margin-right: 10%;
}
.description{
    height: 100vb;
    object-fit: cover;
}
.btn-full-width {
    width: 100%;
}

.css-79ws1d-MuiModal-root {
    z-index: 0 !important;
}

.titleModal{
    padding-bottom: 15px;
    border-bottom: solid 1px #e9e9e9;
    width: 100%;
}

.divisor{
    border-bottom: solid 1px #e9e9e9;
}

.divisorResource{
    border-bottom: solid 1px #e9e9e9;
    padding-top: 20px;
}

.btn-primary-yellow-1:hover{
    background-color: #fbc987;
}
.btn-footer-right{
    position: absolute;
    bottom: 0;
    right: 0;
}

.card-switch{
    background-color: var(--light-background-background-1);
}
.dark .card-switch{
    background-color: var(--dark-background-background-1);
}

img.bold-image-lineup {
    width: 45px;
    max-height: 45px;
    min-height: 45px;
    border-radius: 50%;
    border: none;
    margin-right: 10px;
}

.btn-rounde {
    border-radius: 50%;
    min-width: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    background: #fff !important;
}

.card-calendar-render{
    border: solid 0.5px;
    border-color: #254C99;
    border-left: 20px solid #254C99;
    padding: 10px;
    width: 100%;
    margin: "auto";
    border-radius: 10px;
    transition: background-color 0.3s ease;
}

.card-calendar-render:hover{
    background-color: #e7ecf7;
}

.card-calendar{
    padding: 20px;
    width: 100%;
    min-height: 40px;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12) !important;
    border: solid 1px #eeeeee;
}

.card-calendar:hover{
    background-color: #e7ecf7;
    transition: background-color 0.3s ease;
}

.container-card-render{
    padding: 10px;
    width: 100%;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12) !important;
    border: solid 1px #eeeeee;
}

img.bold-image-select {
    width: 25px;
    max-height: 25px;
    min-height: 25px;
    border-radius: 50%;
    border: none;
    margin-right: 10px;
}
.calendar-photo{
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 25px;
    display: none
}

.calendar-photo-render{
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
}

img.bold-avatar-calendar {
    width: 50px;
    max-height: 50px;
    min-height: 50px;
    border-radius: 50%;
    border: none;
    margin-right: 10px;
}

.bold-name-calendar{
    position: absolute;
    top: 157px;
    color: #000000;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
}

.bold-avatar-calendar:hover {
    transform: scale(1.1);
  }

.check-text {
    color: var(--dark-text-text-3);
}
.dark .check-text {
    color: var(--light-text-text-3);
}

.text-check{
    width: 45%;
    margin-right: 5% !important;
}

.checkblue{
    color: blue
}
.checkbox-group{
    display: flex;
    justify-content: space-between;
    margin-right: 30%;
}

.input-group{
    display: flex;
    width: 100%;
}

.check-title{
    color: var(--light-text-text-1);
}

.dark .check-title{
    color: var(--dark-text-text-1);
}
/*componente tabFilters*/
.btn-tab-filters{
    background-color: var(--light-background-background-1);
    margin: 0;
    padding: 0;
}
.tab-filters-component{
    color: var(--light-text-text-1);
}
.tab-filters-component > .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: var(--light-text-text-3);
    background-color: var(--light-primary-blue-1);
    border-color: none;
}

.overViewNotes{
    color: var(--light-text-text-1);
}

.dark .overViewNotes{
    color: var(--light-text-text-1);
}

.close-icon{
    color: var(--light-text-text-1);
}

.dark .close-icon{
    color: var(--dark-text-text-1);
}
.overViewNotes{
    color: var(--light-text-text-1);
}

.dark .overViewNotes{
    color: var(--light-text-text-1);
}

.close-icon{
    color: var(--light-text-text-1);
}

.dark .close-icon{
    color: var(--dark-text-text-1);
}

.background{
    background-color: var(--light-background-background-1);
}

.dark .background{
    background-color: var(--dark-background-background-1);
}

.text-1{
    color: var(--light-text-text-1);
}

.text-2{
    color: var(--light-text-text-2);
}

.dark .text-2{
    color: var(--dark-text-text-2) !important;
}

.dark .new-client {
    background-color: var(--dark-primary-yellow-1);
    color: var(--dark-text-text-6);
}

.dark .repeat-client {
    background-color: var(--dark-status-success-2);
    color: var(--dark-text-text-1);
}

.dark .type-retention {
    background-color: var(--dark-status-alternative-2);
    color: var(--dark-text-text-1);
}

.dark .pre-churn {
    background-color: var(--dark-secondary-hard-orange);
    color: var(--dark-text-text-6);
}

.type-churn {
    background-color: var(--light-status-error-2);
    color: var(--light-text-text-6);
}

.dark .type-churn {
    background-color: var(--dark-status-error-2);
    color: var(--dark-text-text-1);
}


.dark .type-recovered {
    background-color: var(--dark-primary-blue-3);
    color: var(--dark-text-text-3);
}

.dark .type-vip {
    background-color: var(--dark-secondary-black);
    color: var(--dark-primary-yellow-3);
}

.dark .type-block {
    background-color: var(--dark-secondary-red);
    color: var(--dark-text-text-3);
}

.dark .type-default {
    background-color: var(--dark-status-info-2);
    color: var(--dark-secondary-black);
}
.bg-card{
    background-color: var(--light-background-background-1);
    padding: 10px;
    border-radius: 10px;
}

.dark .bg-card{
    background-color: var(--dark-background-background-1);
}

.icon-bg{
    color: var(--light-text-text-1);
}
.dark .icon-bg{
    color: var( --dark-text-text-1);
}
.divider-container {
    display: flex;
    justify-content: center; /* Centra horizontalmente el contenido dentro del contenedor */
}

.divider-color{
    border-color: var(--light-elements-elements-5) !important;
    margin-bottom: 40px !important;
    border-width: 1px !important;
    border-style:inset !important;
}

.divider-fixed{
    position: fixed;
    bottom: 60px;
    width: 92%; 
}


nav.child-menu-filter a {
    padding: 10px;
    display: inline-block;
    text-decoration: none;
    color: var(--light-text-text-2);
    font-weight: 400;
    border-radius: 4px 4px 0px 0px;
    box-sizing: border-box;
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-3);
    font-weight: var( --light-font-weights-source-sans-pro-0);
}

nav.child-menu-filter {
    background: var(--light-background-background-1);
    margin: 10px 0px 30px;
    border-radius: 4px;
}

.dark nav.child-menu-filter a {
    color: var(--dark-text-text-2);
}

.dark nav.child-menu-filter {
    background: var(--dark-background-background-1);
}
.p1ParagraphBold18{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-4);
    font-weight: var( --light-font-weights-source-sans-pro-1);
    color: var(--light-text-text-1);
}

.p1ParagraphRegular18{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-4);
    font-weight: var( --light-font-weights-source-sans-pro-0);
    color: var(--light-text-text-1);
}

.p1ParagraphSemiBold18{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-4);
    font-weight: var( --light-font-weights-source-sans-pro-2);
    color: var(--light-text-text-1);

}
.p2ParagraphRegular16{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-3);
    font-weight: var( --light-font-weights-source-sans-pro-0);
    color: var(--light-text-text-2);
}

.p2ParagraphSemiBold16{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-3);
    font-weight: var( --light-font-weights-source-sans-pro-2);
}
.p2ParagraphBold16{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-3);
    font-weight: var( --light-font-weights-source-sans-pro-1);
    color: var(--light-text-text-1);
}
.p2ParagraphSemiBold16{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-3);
    font-weight: var( --light-font-weights-source-sans-pro-2);
    color: var(--light-text-text-1);
}

.p1ParagraphRegular18{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-4);
    font-weight: var( --light-font-weights-source-sans-pro-2);
    color: var(--light-text-text-2);
}

.p2ParagraphSemiBold16{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-3);
    font-weight: var( --light-font-weights-source-sans-pro-2);
    color: var(--light-text-text-2);
}

.p3ParagraphSemiBold14{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-2);
    font-weight: var( --light-font-weights-source-sans-pro-2);
    color: var(--light-text-text-2);
}

.p3ParagraphBold14{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-2);
    font-weight: var( --light-font-weights-source-sans-pro-1);
    color: var(--light-text-text-6);
}

.dark .p3ParagraphBold14{
    color: var(--dark-text-text-6);
}

.p3ParagraphRegular14{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-2);
    font-weight: var( --light-font-weights-source-sans-pro-0);
    color: var(--light-text-text-2);
}

.captionSemiBold10{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-0);
    font-weight: var( --light-font-weights-source-sans-pro-1);
    color: var(--light-text-text-6);
}

.captionSemiBold12{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-1);
    font-weight: var( --light-font-weights-source-sans-pro-2);
    color: var(--light-text-text-2);
}

.captionRegular10{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-0);
    font-weight: var( --light-font-weights-source-sans-pro-0);
    color: var(--light-text-text-2);
}

.captionRegular12{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-1);
    font-weight: var( --light-font-weights-source-sans-pro-0);
    color: var(--light-text-text-2);
}

.h1headline36{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-10);
    font-weight: var( --light-font-weights-source-sans-pro-1);
    color: var(--light-text-text-1); 
}

.h1headline32{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-8);
    font-weight: var( --light-font-weights-source-sans-pro-1);
    color: var(--light-text-text-1); 
}

.h2headline28{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var(--light-font-size-6);
    font-weight: var( --light-font-weights-source-sans-pro-1);
    color: var(--light-text-text-1); 
}

.h3headline24{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-5);
    font-weight: var( --light-font-weights-source-sans-pro-1);
    color: var(--light-text-text-1); 
}
.error-text{
    color: var(--light-status-error);
}

.captionBold12{
    font-family: var(--light-font-families-source-sans-pro);
    font-size: var( --light-font-size-1);
    font-weight: var( --light-font-weights-source-sans-pro-1);
    color: var(--light-text-text-2);
}

.dark .captionBold12{
    color: var(--dark-text-text-2);
}

.dark .p1ParagraphBold18{
    color: var(--dark-text-text-1);
}

.dark .p1ParagraphSemiBold18{
    color: var(--dark-text-text-1);
}

.dark .p1ParagraphRegular18{
    color: var(--dark-text-text-2);
}

.dark .p2ParagraphRegular16{
    color: var(--dark-text-text-2);
}

.dark .p2ParagraphBold16{
    color: var(--dark-text-text-1);
}
.dark .p2ParagraphSemiBold16{
    color: var(--dark-text-text-1);
}

.dark .p2ParagraphSemiBold16{
    color: var(--dark-text-text-2);
}

.dark .p3ParagraphSemiBold14{
    color: var(--dark-text-text-2);
}

.dark .p3ParagraphRegular14{
    color: var(--dark-text-text-2);
}

.dark .captionSemiBold10{
    color: var(--dark-text-text-6);
}

.dark .captionRegular12{
    color: var(--dark-text-text-2);
}

.dark .h1headline36{
    color: var(--dark-text-text-1); 
}

.dark .h1headline32{
    color: var(--dark-text-text-1); 
}

.dark .h2headline28{
    color: var(--dark-text-text-1); 
}

.dark .h3headline24{
    color: var(--dark-text-text-1); 
}

.dark .captionSemiBold12{
    color: var(--dark-text-text-2);
}

.dark .captionRegular10{
    color: var(--dark-text-text-2);
}

.dark .error-text{
    color: var(--dark-status-error);
}

.dark .p2ParagraphSemiBold16{
    color: var(--dark-text-text-1);
}

.dropdown-item{
    border-bottom: 0px
}

.dark .dropdown-item:hover {
    background-color:  var(--dark-elements-elements-1);
}
.add-tips-screen{
    font-size: 24px;
}
.footer-form{
    position: fixed;
    bottom: 50px;
    right: 20px;
}

.container-form{
    flex: 1;
    overflow-y: auto;
}

.footer-form {
    position: sticky;
    bottom: 0;
    width: 100%;
    border-top: 1px solid var(--light-neutral-gray-3);
    padding: 10px 30px;
    background: var(--light-background-background-2);
    z-index: 3;
}

.dark .footer-form {
    background: var(--dark-background-background-2);
}

.dropdown-menu-barber{
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.dropdown-menu-barber > li{
    padding: 3px 0px;
}


.divider-fixed{
    position: fixed;
    bottom: 60px;
    width: 92%; 
}

.dark .divider-color{
    border-color: var(--dark-elements-elements-5) !important;
}

.divider-header{
    border-color: var(--light-elements-elements-5) !important;
    border-width: 1px !important;
    border-style:inset !important;
}

.toastIconClose{
    position: absolute;
    top: 20px;
    left: 95%;
    margin-left: -15px;
}

.toast-notfy{
    position: absolute;
    top: 21px;
    font-size: 14px;
    width: 70%;
    overflow-wrap: break-word;
    white-space: normal;
}

.toast-notfy-text{
    position: absolute;
    top: 21px;
    left: 40px;
    font-size: 14px;
    width: 70%;
    overflow-wrap: break-word;
    white-space: normal;
}

.toast-success{
    background: var(--light-status-success-2);
    color: var(--light-status-success);
}

.dark .toast-success{
    background: var(--dark-status-success-2);
    color: var(--dark-status-success);
}

.dark .toast-text-success{
    background: var(--dark-status-success-2);
    color: var(--dark-status-success);
}

.toast-warning {
    background: var(--light-status-info-2);
    color: var(--light-status-info);
}

.dark .toast-warning {
    background: var(--dark-status-info-2);
    color: var(--dark-status-info);
}

.toast-error {
    background: var(--light-status-error-2);
    color: var(--light-status-error);
}

.dark .toast-error {
    background: var(--dark-status-error-2);
    color: var(--dark-status-error);
}


.categories-form-container {
    height: 65vh
}
.color-text-2{
    color: var(--light-text-text-2);
}

.dark .color-text-2{
    color: var(--dark-text-text-2);
}

.color-text-1{
    color: var(--light-text-text-1);
}

.dark .color-text-1{
    color: var(--dark-text-text-1);
}
.check-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 0px 0px;
}
.addbutton-options {
    width: 85%;
}
.style-tab-filter {
    margin: 0px 0px 0px!important;
}
.day-text {
    color: var(--light-text-text-1);
}

.day-text-disabled {
    color: var(--light-text-text-2);
}

.dark .day-text {
    color: var(--dark-text-text-1); 
}

.dark .day-text-disabled {
    color: var(--dark-text-text-2); 
}