.titleCommissions{
    color: var(--light-text-text-1);
}

.commissionsContainer{
    background: var(--light-background-background-1);
}

.textCommissions{
    color: var(--light-text-text-2);
    font-size: 18px;
    margin-left: 42px;
}

.marginless{
    margin: 0;
}

/* MODO OSCURO */

.dark .titleCommissions{
    color: var(--dark-text-text-1);
}

.dark .commissionsContainer{
    background: var(--dark-background-background-1);
}

.dark .textCommissions{
    color: var(--dark-text-text-2);
}