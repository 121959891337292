.cardStyle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border-width: 10px;
    border: solid;
    padding: 20px;
}

.cardColor{
    background-color: var(--light-background-background-1);
    border-color: var(--light-elements-border-panels-1);
}

.dark .cardColor{
    background-color: var(--dark-background-background-1);
    border-color: var(--dark-elements-border-panels-1);

}

.image {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 10px;
}

.iconContainerStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
}

.colorImage {
    color: var(--light-text-text-3);
    background-color: var(--light-status-success); 
}

.dark .colorImage {
    color: var(--dark-text-text-3);
    background-color: var(--light-status-success);
}

.textContainerStyle {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid var(--light-elements-border-panels-1);
}

.buttonContainerStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.borderColorButtonUpload i{
    color: var(--light-text-text-1);
}
.dark .borderColorButtonUpload i{
    color: var(--dark-text-text-1);
}

.dark .borderColorButton{
    color: var(--dark-text-text-1);
}

.textCard {
    font-size: var(--light-font-size-2);
    font-weight: var(--light-font-weights-source-sans-pro-2);
    margin: 0;
}
.colorText {
    color: var(--light-text-text-1)
}

.dark .colorText {
    color: var(--dark-text-text-1)
}

.bold{
    font-weight: var(--light-font-weights-source-sans-pro-1);
}

.regular{
    font-weight: var(--light-font-weights-source-sans-pro-0);

}
