.right-modal-status-body {
    background: var(--light-background-background-2);
    flex: auto;
    max-height: 100vh;
    overflow: auto;
    padding: 25px 20px;
}

.dark .right-modal-status-body {
    background: var(--dark-background-background-2)
}

.footer-rigth-modal-generic-status {
    background: var(--light-background-background-2);
    flex: 0 1;
    display: flex;
    align-items: center;
    padding: 25px 20px;
}

.dark .footer-rigth-modal-generic-status {
    background: var(--dark-background-background-2)
}