
.dropdown button.btn.btn-primary-yellow-1{
    background: var(--light-primary-yellow-1);
}

.dropdown button.btn.btn-primary-yellow-1:hover{
    background: var(--light-primary-yellow-3);
}

.dropdown button.btn.btn-primary-yellow-1.show{
    background: var(--light-primary-yellow-2);
}

.dark .dropdown button.btn.btn-primary-yellow-1{
    background: var(--light-primary-yellow-1);
}

.dark .dropdown button.btn.btn-primary-yellow-1:hover{
    background: var(--light-primary-yellow-3);
}

.dark .dropdown button.btn.btn-primary-yellow-1.show{
    background: var(--light-primary-yellow-2);
}