.barberCardImage {
    display: flex;
    background-color: var(--light-primary-blue-1);  
    color: white;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    text-align: center;
    align-content: center;
}

.dark .text-datepicker svg{
    color: var(--dark-text-text-1);
}

.table-payroll .rdt_TableHeadRow > div[data-column-id]{
    padding-left: 5px;
    padding-right: 5px;
}

.table-payroll .rdt_TableCol_Sortable > div[data-column-id]{
    text-overflow: inherit;
    white-space: wrap;
}

.block-container-filter {
    height: 65px;
    width: 65%;          
    display: flex;              
    align-items: center;        
    gap: 8px;                   
    padding: 8px;               
    border: 1px solid #dee2e6;  
    border-radius: 8px;         
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    background-color: var(--light-background-background-1);
}

.dark .block-container-filter {
    background-color: var(--dark-background-background-1);
}
