.field-schedule {
    background-color: var(--light-background-background-1);
    border-radius: 10px;
    position: relative;
    min-height: 40px;
    box-sizing: border-box;
    border-color: var(--light-primary-blue-2) !important;
    border: solid 1px;
}
.field-schedule.disabled {
    min-height: 45px;
    opacity: 0.5;
    border-color: var(--light-elements-elements-1) !important;
    border: solid 1px;
    background-color: var(--light-background-background-2);
}

.label-field{
    display: block;
    position: absolute;
    top: 10px;
    margin: 0px 6px;
    padding: 0px 5px;
    color: var(--light-text-text-2);
    font-size: 14px;
    background-color: var(--light-background-background-1);
}

.label-field.disabled{
    margin-top: 2px;
    background-color: var(--light-background-background-2);
}

.value-field{
    padding: 10px;
    display: block;
    color: var(--light-text-text-2);
}

.field-schedule:has(.value-field:not(:empty)) .label-field {
    top: -10px; /* Sube cuando hay texto */
    transform: translateY(0);
}

.icon-container{
    /* background-color: red; */
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* #####################
    MODO OSCURO
######################## */
.dark .field-schedule{
    background-color: var(--dark-background-background-1);
}

.dark .label-field{
    color: var(--dark-text-text-2);
    background-color: var(--dark-background-background-1);
}

.dark .value-field{
    color: var(--dark-text-text-2);
}