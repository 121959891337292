.familyCard {
    background-color: var(--light-background-background-1);
    border-radius: 10px;
    border: solid 1px var(--light-elements-border-panels-1);
    padding-top: 7px;
    padding-bottom: 7px;
}
.dark .familyCard{
    background-color: var(--dark-background-background-1);
    border: solid 1px var(--dark-elements-border-panels-1);
    
}
.familyNextButton {
    width: 30px;
    height: 30px;
    border: var(--light-elements-border-panels-1) solid 1px;
    border-radius: 50%;
    overflow: hidden;
    background-color: transparent;
    
}
.familyNextButton:hover {
    background-color: var(--light-primary-yellow-3);
    border-color: var(--light-primary-yellow-3);
}
.familyProblemButton {
    width: 30px;
    height: 30px;
    border: var(--light-primary-yellow-3) solid 1px;
    border-radius: 50%;
    overflow: hidden;
    background-color: transparent;
}
.familyCardImage {
    display: flex;
    background-color: transparent;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border: solid 1px var(--dark-elements-border-panels-1);
    text-align: center;
    align-content: center;
}

.customer-chips .chips{
    width: fit-content !important;
    padding: 0px 10px;
}

.familyInfo {
    height: 55px;
}
.status-nuevo {
    background-color: var(--light-primary-yellow-3);
    border: 1px solid var(--light-elements-border-panels-1);
    color: var(--light-text-text-1) !important;
}

.status-container {
    height: 25px;
    border-radius: 20px;
    color: var(--light-text-text-6);
}
.card-title-name{
    color: var(--light-text-text-1);
    font-size: 16px;
}
.card-title-members{
    color: var(--light-text-text-2);
    font-size: 14px;
}
.dark .card-title-name{
    color: var(--dark-text-text-1);
}
.dark .card-title-members{
    color: var(--dark-text-text-2);
}