.subtitle-create-form{
    font-weight: var(--light-font-weights-source-sans-pro-2);
    font-size: var(--light-font-size-3);
}
.color-subtitle-create-form{
    color: var(--light-text-text-1);
}

.text-toogle{
    font-weight: var(--light-font-weights-source-sans-pro-0);
    font-size: var(--light-font-size-2);
}
.description-service{
    color: var(--light-text-text-1);
    font-weight: var(--light-font-weights-source-sans-pro-2);
}

.name-form {
    font-weight: var(--light-font-weights-source-sans-pro-2);
    font-size: var(--light-font-size-4);
}
.subcontainerDetails {
    border-radius: 10px;
    margin: 1.5rem;
    background-color: var(--light-neutral-white);
    padding: 10px;
}

.container-duration-services{
    display: flex;
    border-top: 1px solid var(--light-elements-elements-4);
    justify-content: space-between;
    padding: 10px 0;
}

/*Dark mode*/
.dark .color-subtitle-create-form{
    color: var(--dark-text-text-1);
}
.dark .description-service {
    color: var(--dark-text-text-1);
}
.dark .subcontainerDetails {
    background-color: var(--dark-neutral-white);
}