.addonsBtn {
    align-items: center;
    font-weight: 600;
    color: var(--light-text-text-1);
    background-color: var(--light-primary-blue-5);
    border-radius: 25px;
    border: none
}

.addonsBtn:hover {
    background-color: var(--light-primary-blue-4);
}

.addonsBtn:active {
    background-color: var(--light-primary-blue-1);
    color: var(--light-neutral-white);
}

.iconAddButton svg {
    fill: var(--light-text-text-1);
    margin-right: 2px;
    display: flex;
    width: 10px;
    height: 10px;
}

.addonsBtn:active .iconAddButton svg {
    fill: var(--light-neutral-white);
}

.close-icon-out svg {
    fill: var(--light-secondary-black);
    margin-left: 16px;
    cursor: pointer;
    width: 15px;
    height: 15px;
}

.dark .close-icon-out svg {
    fill: var(--dark-secondary-black);
}

.input-selector-container {
    position: relative;
    margin-bottom: 10px;
}

.input-selector {
    background-color: var(--light-neutral-white);
    padding: 8px;
    font-size: 16px;
    border: 1px solid var(--light-neutral-gray-3);
    border-radius: 10px;
    width: 100%;
    padding-right: 40px;
}

.dark .input-selector {
    background-color: var(--dark-neutral-white);
    border: 1px solid var(--dark-neutral-gray-3);
}

.dropdown-optionsAddons {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid var(--light-neutral-gray-3);
    background-color: var(--light-neutral-white);
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.dark .dropdown-optionsAddons {
    color: var(--dark-text-text-2);
    border: 1px solid var(--dark-neutral-gray-3);
    background-color: var(--dark-neutral-white);
}

.dropdown-optionsAddons li {
    padding: 8px;
    cursor: pointer;
}

.dropdown-optionsAddons li:hover {
    background-color: var(--light-neutral-white);
}

.dark .dropdown-optionsAddons li:hover {
    background-color: var(--dark-neutral-white);
}

.card-addons {
    width: 80%;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--light-neutral-white);
}

.dark .card-addons {
    background-color: var(--dark-neutral-white);
}

.card-close-icon {
    cursor: pointer;
}

.card-trash-icon {
    fill: #FF0000;
    cursor: pointer;
    margin-left: 30px;
    margin-right: 10px;
}

.clear-icon {
    position: absolute;
    right: 0.5rem; /* Adjust as needed */
    cursor: pointer;
    fill: var(--light-neutral-gray-3); /* Color of the icon */
    width: 1rem; /* Adjust size */
    height: 1rem; /* Adjust size */
}

.dark .clear-icon {
    fill: var(--dark-neutral-gray-3); /* Color of the icon */
}

.icon-containerAddons {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}

.search-icon {
    fill: var(--light-text-text-2);
    cursor: pointer;
    margin-left: 5px;
}

.close-icon {
    fill: var(--light-text-text-2);
    cursor: pointer;
    margin-left: 5px;
}

.dark .search-icon {
    fill: var(--dark-text-text-2);
}

.dark .close-icon {
    fill: var(--dark-text-text-2);
}

.input-wrapperAddons {
    position: relative;
    width: 30%;
    display: flex;
    align-items: center;
}

.tittleAddons{
    font-size: 14px;
    font-weight: 600;
    color: var(--light-text-text-2);
}

.dark .tittleAddons{
    color: var(--dark-text-text-2);
}

.descriptionAddons{
    font-size: 13px;
    color: var(--light-text-text-2);
}

.dark .descriptionAddons{
    color: var(--dark-text-text-2);
}

.timeAddons{
    color: var(--light-neutral-gray-1);
    font-size: 13px;
}

.dark .timeAddons{
    color: var(--dark-neutral-gray-1);
}

.priceCardAddons{
    font-size: 14px;
    color: var(--light-text-text-2);
}

.dark .priceCardAddons{
    color: var(--dark-text-text-2);
}

img.barber-image-inside {
    border-radius: 25px;
    width: 100%;
    height: 100%;
}