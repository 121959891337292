/*
Estos ficheros contienen las clases y estilos definitivos de la aplicacion - no de estilos de barberlytics.
*/
@import url("./../css/BoldInputPhone.css");

body{
    font-family: 'Source Sans 3' !important;
}

.btn-action-dash{
    border:0px;
    display: inline-block;
    padding: 4px;
    margin: 0px 2px;
    background: transparent;
}
.background-1{
    background-color: var(--light-background-background-1);
}
/* ############################
FORMULARIOS - clases para formularios ejemplo de uso en categories Form
###############################
*/
.container-form-global {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}
.container-form-global > form {
    display: flex;
    flex: 4;
    flex-direction: column;
}

.section-form {
    flex: 4 1;
}
.section-footer {
    position: sticky;
    bottom: 0px;
    padding: 15px 0px;
}

/* Login */
.section-login {
    background: var(--light-background-background-1);
    box-shadow: 0px 0px 16px rgb(197 194 194);
    color: var(--light-text-text-2);
    padding: 20px 40px;
    border-radius: 20px;
    min-height: 600px;
    max-width: 420px;
    width: 70%;
}

.title-login {
    color: var(--light-text-text-1);
    font-size: 18px;
    font-weight: 600;
    margin: 30px 0px 20px;
}

button.help-float {
    position: fixed;
    bottom: 20px;
    right: 10px;
    background: var(--light-elements-elements-10);
    border: 0;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.help-float-color-icon{
    color: var(--light-text-text-3);
    font-size: 20px;
}

.MuiInputBase-input {
    border: 1px solid var(--light-neutral-gray-2);
    border-radius: 4px;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border-color: var(--light-neutral-gray-2);
}

.text-box-policies {
    display: block;
    background: #fafafa;
    min-height: 300px;
    max-height: 360px;
    border-radius: 4px;
    padding: 0px;
    overflow: hidden;
}

/* ################
Text 
################*/
.regular {
    font-weight: 600;
}

.light {
    font-weight: 300;
}
.small-text{
    font-size: 13px;
}

.text-green {
    color: rgb(5, 171, 11);
}

.text-orange {
    color: rgb(219, 129, 38);
}

.text-red {
    color: var(--light-status-error);
}

.text-gray {
    color: rgb(159, 159, 159) !important;
}
.bold-text-field-material textarea{
    font-size: 13px !important;
}

/*  ################
    ASIDE
    ################ */
.aside {
    min-height: 100vh;
    display: block;
    float: left;
    max-width: 250px;
    position: fixed;
    background: var(--light-primary-blue-2);
    left: 0px;
    transition: all .3s linear !important;
    z-index: 10;
}
.aside.hide {
    width: 70px;
}
.aside.show {
    width: 250px;
}

.aside.hide .menu-item-name, .aside.hide a:after{
    display: none;
}
.aside.hide li.menu-item a {
    display: flex;
    justify-content: center;
    padding: 0px;
}
.aside.hide li.menu-item a i.fa-solid, .aside.hide li.menu-item a i.fa-regular{
    padding-right: 0px;
}

li.menu-item a{
    height: 54px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--light-text-text-3);
    font-weight: 100;
    line-height: 30px;
    padding: 4px 5px 4px 20px;
    margin: 2px 0px;
}

span.menu-item-name{
    padding-left: 10px;
}
/* Estilo de fontawesome en menu */
li.menu-item a i.fa-solid, li.menu-item a i.fa-regular{
    padding-right: 10px;
}

/* Menu oculto con hover */
.aside.hide:hover{
    width: 250px;
}
.aside.hide:hover .menu-item-name, .aside.hide:hover a:after{
    display: block;
    transition: all .3s linear;
}
.aside.hide:hover li.menu-item a{
    display: flex;
    justify-content: inherit;
    padding: 4px 5px 4px 20px;
    margin: 2px 0px;
    transition: all .3s linear;
}
.aside.hide:hover li.menu-item a i.fa-solid, .aside.hide:hover li.menu-item a i.fa-regular{
    padding-right: 10px;
    transition: all .3s linear;
}

.aside.hide li.menu-item.collapsable ul{
    display: none;
}
.aside.hide:hover li.menu-item.collapsable ul{
    display: block;
}

/* Flechas de elementos de menu */
li.menu-item.collapsable>a:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    right: 8px;
    top: 25px;
    border-bottom: 2px solid #bababa;
    border-right: 2px solid #bababa;
    transform: rotate(225deg);
}
li.menu-item.collapsable>a.collapsed:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    right: 8px;
    top: 20px;
    border-bottom: 2px solid #bababa;
    border-right: 2px solid #bababa;
    transform: rotate(45deg);
}

/* MAIN HEADER */
.container-menu {
    background: var(--light-background-background-1);
    padding: 0px 10px;
}
.btn-action-dash span.material-symbols-outlined {
    position: relative;
    top: 3px;
    font-size: 22px;
}
.actions-dashboard {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;
}
.title-dashboard {
    padding-left: 6px;
    color: var(--light-text-text-1);
}
span.location-label {
    font-size: 13px;
    color: var(--light-text-text-1);
    line-height: 13px;
}

.dropdown-toggle.btn-location, .btn-location {
    border: 1px solid var(--light-elements-border-panels-1);
    border-radius: 10px;
    padding: 0px 10px;
    text-align: left;
}
ul.dropdown-menu.show {
    max-height: 80vh;
    overflow: auto;
}

/* CONTENT - CONTENIDO - PANTALLAS - SCREENS */
.title-page {
    margin: 0px;
    color: var(--light-text-text-1);
}
.screen-count-elements{
    color: var(--light-text-text-2);
    font-size: 0.9rem;
}
.dark .screen-count-elements{
    color: var(--light-text-text-2);
}

/*  ################
    SECTION PAGE CHILD MENU
################ */
/* nav.child-menu a.active {
    border-top: solid 4px  var(--light-primary-blue-1);
    background: var(--light-background-background-2);
    color: var(--light-primary-blue-1);
} */

nav.child-menu a {
    padding: 10px;
    display: inline-block;
    text-decoration: none;
    color: var(--light-text-text-2);
    font-weight: 400;
    border-radius: 4px 4px 0px 0px;
    box-sizing: border-box;
}

nav.child-menu {
    background: var(--light-background-background-1);
    margin: 10px 0px 30px;
    border-radius: 4px;
}

/* Filtros - Dashboard */
.filters{
    color: var(--light-text-text-1);
}

/* FOOTER */
.company-branch-link {
    font-weight: 700;
    text-decoration: none;
    color: #272751;
}
.footerBotonGuardar {
    margin-top: 20px;
    text-align: end;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

/* Components - Componentes */
/* Bold Select */
.bold-select{
    background: var(--light-background-background-1);
    border-radius: 10px !important;
}

/* INPUTS */
.input-icon {
    position: relative;
    width: 100%;
}
/* input search */
.input-icon.input-search .clear{
    color: #df0606;
    right: 32px;
    font-size: 16px;
    top: 12px;
    cursor: pointer;
    height: 300px;
}

.input-icon input {
    width: 100%;
    margin-right: 15px;
    border: 1px solid var(--light-neutral-gray-2);
    border-radius: 8px;
    height: 40px;
    padding-right: 30px;
    padding-left: 10px;
    font-size: 14px;
}
.input-icon span {
    position: absolute;
    top: 6px;
    right: 5px;
    color: #a5a5a5;
    text-shadow: 2px 1px 2px #e0dddd;
}
.input-iconBar {
    position: absolute;
    top: 12px;
    right: 10px;
    color: #a5a5a5;
    text-shadow: 2px 1px 2px #e0dddd;
}

.bold-input-phone label {
    padding-left: 60px;
}

/*################
    BUTTONS
################## */
.btn-light{
    background: transparent;
    color: var(--light-text-text-1);
    border: 1px solid var(--light-elements-elements-3) !important;
}

/*################
    TABLE
################## */

.counter-data-table{
    font-size: 14px;
    padding-left: 20px;
    color: var(--light-text-text-2);
}

button.btn-table-actions {
    color: var(--light-text-text-2);
    text-shadow: none;
}

.rdt_TableHead, .rdt_TableHeadRow{
    background-color: var(--light-background-background-1) !important;
}

.rdt_TableCol {
    color: var(--light-text-text-2);
    font-size: 14px;
    font-weight: 700;
    border-right: 1px solid var(--light-elements-elements-5);
}

.rdt_TableCell {
    color: var(--light-text-text-2);
    font-weight: 400;
    border-right: 1px solid var(--light-elements-elements-5);
}

.rdt_TableBody > div:nth-child(odd) {
    background: var(--light-background-background-2);
}

.rdt_TableBody > div:nth-child(even) {
    background: var(--light-background-background-1);
}

nav.rdt_Pagination {
    background: transparent;
    border: 0px;
    font-weight: 500;
    font-size: 16px;
    color: var(--light-text-text-2);
}

.sc-ezOQGI.hoQsHK button svg{
    color: var(--light-text-text-2);
    fill: var(--light-text-text-2);
}

.item-table-action{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    font-size: 0.9em;
    text-align: left;
    align-items: center;
}

.item-table-action span{
    color: var(--light-text-text-1);
}