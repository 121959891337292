.filtered-norifications-dash {
    position: absolute;
    top: 51px;
    left: 1px;
    width: 100%;
}

.body-notifications-dash{
    margin-top: 32px;
    display: flex;
    align-items: center;
    min-height: 70vh;   
    flex-direction: column;
}