.background-transaction{
    background-color: var(--light-background-background-2);
}
.dark .background-transaction{
    background-color: var(--dark-background-background-2);
}
.container-modal{
    max-height: 80%;
    padding: 2% 5% 2% 5%;
    border-radius: 10px;

}
.title-font-transaction {
    font-size: var(--light-font-size-3);
    font-weight: var(--light-font-weights-source-sans-pro-2);
}
.container-data-transaction{
    max-height: 90%;
    overflow: scroll;
    padding: 10px 15px;
    border-radius: 10px;

}
.background-container-data-transaction{
    background-color: var(--light-background-background-1);
}
.dark .background-container-data{
    background-color: var(--dark-background-background-1);
}