@import url(./../../../assets/css-generated/variables.css);

.simplelist-container{
    padding: 0 10px;
}
.simplelist-card{
    display: flex;
    align-items: center;
    padding: 5px;
    display: flex;
    gap: 20px;
}
.simplelist-image{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    min-width: 70px;
    min-height: 70px;
    width: 70px;
    height: 70px;
    overflow: hidden;

}
.simplelist-image img{
    width: 100%;
}
.simplelist-title{
    display: flex;
    gap: 10px;
    font-size: var(--light-font-size-3);
    color: var(--light-text-text-1);
    margin-bottom: 5px;
}
.simplelist-title p{
    margin: 0;
}
.simplelist-title span{
    color: var(--light-text-text-8);
}
.simplelist-text{
    margin: 0;
    color: var(--light-text-text-8);
    font-size: var(--light-font-size-1);
}
.width-divider{
    border: 1px solid var(--light-elements-elements-2);
    margin: 5px -34px;
}
.simplelist-text-name{
    margin: 0;
    color: var(--light-text-text-1);
    font-size: var(--light-font-size-1);
}
/*dark*/
.dark .simplelist-container{
    background: var(--dark-background-background-1);
}
.dark .simplelist-title{
    color: var(--dark-text-text-1);
}
.dark .width-divider{
    border: 1px solid var(--dark-elements-elements-1);
}
.dark .simplelist-text-name{
    margin: 0;
    color: var(--dark-text-text-1);
    font-size: var(--light-font-size-1);
}
