.timerestriction_card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 10px 0px;
    background-color: var(--light-background-background-1);
    box-shadow: 0px 0px 3px #9e9e9e57;
}

.timerestriction_textcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.timerestriction_textcard {
    font-size: var(--light-font-size-2);
    font-weight: var(--light-font-weights-source-sans-pro-2);
    margin: 0;
    color: var(--light-text-text-1)
}
.timerestriction_textcardtime {
    font-size: var(--light-font-size-2);
    font-weight: var(--light-font-weights-source-sans-pro-0);
    margin: 0;
    color: var(--light-text-text-1)
}
.timerestriction_button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: var(--light-background-background-1);
    color: var(--light-text-text-1);
}

.colorText {
    color: var(--light-text-text-1)
}
.trash-color{
    color: var(--dark-status-error);
}

/*DARK MODE*/
.dark .timerestriction_card{
    background-color: var(--dark-background-background-1);
}
.dark .timerestriction_textcard{
    color: var(--dark-text-text-1)
}
.dark .timerestriction_textcardtime{
    color: var(--dark-text-text-1)
}
.dark .colorText {
    color: var(--dark-text-text-1)
}
.dark .timerestriction_button {
    border-color: var(--dark-elements-elements-1);
    color: var(--dark-text-text-1);
    background-color: var(--dark-background-background-1);
}