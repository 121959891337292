.cardBookingContainer{
    background: var(--light-background-background-1);
}

.textBookingColor{
    color: var(--light-text-text-2);
}

/* MODO OSCURO */

.dark .cardBookingContainer{
    background: var(--dark-background-background-1);
}

.dark .textBookingColor{
    color: var(--dark-text-text-2);
}

.seccion-one{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.viñeta::before {
    content: "•"; 
    font-size: 24px; 
    color: var(--light-text-text-2); 
    margin-right: 8px; 
    vertical-align: middle;
  }

  .dark .viñeta::before {
    color: var(--dark-text-text-2); 
  }