.custom-select {
    position: relative;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 16px;
}

.custom-select-trigger {
    background-color: var(--light-background-background-1);
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
}

.dark .custom-select-trigger {
    background-color: var(--dark-background-background-1);
}

.custom-select-trigger i {
    margin-right: 10px;
    font-size: 18px;
}

.custom-select-trigger:hover {
    border-color: #888;
}

.custom-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--light-background-background-1);
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.dark .custom-options {
    background-color: var(--dark-background-background-1);
}

.custom-options li {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
}

.custom-options li i {
    margin-right: 10px;
    font-size: 18px;
}

.custom-options li:hover {
    background-color: var(--light-background-background-2)
}

.custom-options li:active {
    background-color: #e9e9e9;
}

.dark .custom-options li:hover {
    background-color: var(--dark-background-background-2);
}


