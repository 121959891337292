.content-rigth-modal-checkout-status-list {
    background: var(--light-background-background-2) !important;
    flex: auto;
    max-height: 100vh;
    overflow: auto;
}

.dark .content-rigth-modal-checkout-status-list {
    background: var(--dark-background-background-2) !important;
}

.form-control{
    background-color: var(--light-background-background-1);
}

.dark .form-control{
    background-color: var(--dark-background-background-1);
    color: var(--dark-text-text-1);
}

.select-container {
    position: relative;
}

.select-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px;
    color: #aaa;
    font-size: 15px;
    pointer-events: none;
    transition: all 0.3s ease;
}

/* Muestra el placeholder solo si no hay valor seleccionado */
.select-placeholder.visible {
    opacity: 1;
}

/* Oculta el placeholder si hay un valor seleccionado */
.select-placeholder.hidden {
    opacity: 0;
    pointer-events: none;
}

/* Estilo opcional para el textarea */
.form-control::placeholder {
    color: #aaa;
}

.block-body-checkout {
    padding: 0px 20px;
}