.container-uploads {
    display: flex;
    align-items: center;
    margin: 20px;
}

/* .section-bold-upload-files {
    display: block;
    height: 200px;
    width: 100%;
    background: #f0f2f5;
    border: 3px dashed #cacaca;
    border-radius: 10px;
    padding: 10px;
    overflow: auto;
} */
.section-bold-upload-files {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
    background: var(--light-elements-elements-2);
    border: 3px dashed var(--light-elements-elements-4);
    border-radius: 10px;
    padding: 10px;
    overflow: auto;
}
.dark .section-bold-upload-files {
    background: var(--dark-elements-elements-2);
    border: 3px dashed var(--dark-elements-elements-4);
}

.section-bold-upload-files img {
    width: 120px;
    max-height: 140px;
    margin: 0px 12px;
}

.inner-upload {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.background-button-white{
    background-color: var(--light-elements-elements-2);
    border: 1px solid var(--dark-elements-elements-1);
    display: block;
    width: 100%;
    padding: 8px 10px;
    border-radius: 10px;
    margin: 4px 0px;
    color: var(--dark-text-text-1);
}

.inner-upload .bold-btn {
    border: 0px;
}

span.title-upload {
    font-size: 17px;
    font-weight: 200;
    margin-top: 10px;
    text-align: center;
    width: 100%;
}
.title-upload-files{
    font-size: 17px;
    font-weight: 200;
    margin-top: 10px;
    text-align: center;
    width: 100%;
    color: var(--light-text-text-1);
}
.dark .title-upload-files{
    color: var(--dark-text-text-1);
}
.icon-button-upload{
    color: var(--light-text-text-1);
}

.inner-upload span.material-symbols-outlined {
    background: #ff9c33;
    color: white;
    width: 55px;
    height: 55px;
    border: 0px;
    border-radius: 50%;
    text-align: center;
    line-height: 55px;
    font-size: 30px;
    box-shadow: 0px 0px 13px #ababab;
}

.buttons-options {
    display: flex;
}

.buttons-options button {
    height: 60px;
    background: transparent;
    border: 0;
    color: #808080;
    font-size: 11px;
}

.buttons-options button span {
    border: 1px dashed #C9CACC;
    border-radius: 5px;
    color: #808080;
    padding: 5px;
}
.circle-image > .container-uploads{
    width: 60px;
    height: 60px;
    position: relative;
}
.circle-image >.container-uploads > .section-bold-upload-files{
    border: none;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: no-repeat url('../img/upload-image.png') #4D689E 50%;
    overflow: hidden;
}
.barberlytics-button-modal{
    position: absolute;
    bottom: 0;
    right: 0;
    background: white;
    border: 1px solid #D8D8D8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 20px;
    width: 20px;
    align-items: center;
}
.barberlytics-button-modal > span{
    font-size: 12px;
}
