.cardBlock {
    width: 100%;
    display: flex;
    align-items: center;
    border: 2px solid var(--dark-elements-border-panels-1); 
    border-left-width: 10px; 
    border-left-style: solid; 
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 62px;
    justify-content: space-between;
}
.cardColorBlock{
    background-color: var(--light-background-background-1);
    border-color: var(--light-services-blue-blue-1);
}
.dark .cardColorBlock{
    background-color: var(--dark-background-background-1);
    border-color: var(--dark-elements-border-panels-1);
}
.blockTextSpace {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    width: 65%;
    padding: 10px 0;
}

.textCardTitleBlock {
    margin: 0;
    color: var(--light-text-text-1);
    font-weight: 700;
    font-size: 13px;
}
.dark .textCardTitleBlock {
    color: var(--dark-text-text-1)
}
.textCardSubtitleBlock{
    font-size: 10px;
    font-weight: 600;
    color: var(--light-text-text-2);
    margin: 0; 
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal
}
.dark .textCardSubtitleBlock{
    color: var(--dark-text-text-2);
}
.blockStatusSpace{
    justify-content: space-between;
}
.statusBlock{
    color: black;
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 25px;
    width: 100px;
    justify-content: center;
    background: transparent;
    margin: 4px 1px;
    padding: 8px 10px;
    border: 1px solid var(--light-elements-elements-1);
    border-radius: 10px;
}
.dark .statusBlock {
    color: white;
}
