.cardStyleInternalLineup {
    width: 98%;
    border-radius: 13px;
    border: solid;
    flex-direction: row;
}
.cardColorLineup{
    background-color: var(--light-background-background-1);
    border-color: var(--light-elements-border-panels-1);
    border-width: 1px;
}
.dark .cardColorLineup{
    background-color: var(--dark-background-background-1);
    border-color: var(--dark-elements-border-panels-1);

}
.imageLineup {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 1px var(--light-neutral-gray-3);
}
.textContainerStyleLineUp {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.textCardLineup {
    font-size: var(--light-font-size-2);
    font-weight: var(--light-font-weights-source-sans-pro-2);
    margin: 0;
    color: var(--light-text-text-1);
}

.dark .textCardLineup {
    color: var(--dark-text-text-1);
}

.icon-bars{
    color: var(--light-text-text-1);
}

.dark .icon-bars{
    color: var(--dark-text-text-1);
}