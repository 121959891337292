.content-loader {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background: #ffffffa3;
    z-index: 10;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.bg-login {
    height: 100vh;
    background-image: url('/public/img/bg-login.jpg');
    background-size: cover;
    background-position: center;
}
img.logo-login {
    width: 100%;
    max-width: 260px;
    display: block;
    margin: 0px auto 30px;
}

.btn-transparent{
    background: transparent;
}
.btn-forget {
    text-align: right;
    font-size: 14px;
    color:#000000 !important;
}
.btn-forget:hover{
    background: rgba(253, 179, 68, 0.6);
}

.footer-login{
    margin-top: 36px;
    border-top: 1px solid rgb(206, 206, 206);
    padding-top: 20px;
}
.terms-link{
    font-weight: 600;
    text-decoration: none;
    color: #3097e7;
}


/* ################
DARK MODE 
###################
*/
.dark
.bg-login {
    height: 100vh;
    background-image: url('/public/img/bg-login-dark.jpeg');
    background-size: cover;
    background-position: center;
}