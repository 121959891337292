.MuiList-root{
    background: var(--light-background-background-1);
}
.MuiList-root .Mui-selected{
    background: var(--light-elements-elements-1) !important;
}
.dark .MuiList-root{
    background: var(--dark-background-background-2);
    color: var(--dark-text-text-1);
}
.dark .MuiList-root .Mui-selected{
    background: var(--dark-text-text-7) !important;
    color: var(--dark-text-text-1);
}