.field-view {
    background-color: var(--light-background-background-1);
    border-radius: 10px;
    position: relative;
    min-height: 40px;
    box-sizing: border-box;
}
.viewfield-border{
    border: 1px solid var(--light-primary-blue-2);
}

.viewfield-label-field{
    display: block;
    position: absolute;
    top: -10px;
    margin: 0px 6px;
    padding: 0px 5px;
    color: var(--light-text-text-2);
    font-size: 14px;
    z-index: 1;
}

.viewfield-background-label {
    font-size: 14px;
    left: 8px;
    position: absolute;
    top: -1px;
    background: var(--light-background-background-1);
    color: transparent;
    z-index: 0;
    padding: 0px 4px;
    height: 3px;
    overflow: hidden;
}

.dark .viewfield-background-label {
    background: var(--dark-background-background-2);
}

.viewfield-value-field{
    padding: 10px;
    display: block;
    color: var(--light-text-text-2);
}
.viewfield-icon-container{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light-text-text-1);
}
/* #####################
    MODO OSCURO
######################## */
.dark .field-view{
    background-color: var(--dark-background-background-1);
}
.dark .viewfield-border{
    border: 1px solid var(--dark-primary-blue-2);
}

.dark .viewfield-label-field{
    color: var(--dark-text-text-2);
}

.dark .viewfield-value-field{
    color: var(--dark-text-text-2);
}
.dark .viewfield-icon-container{
    color: var(--dark-text-text-1);
}