.tips-selected {
    background-color: #007bff; /* Cambia esto al color que desees */
    color: white; /* Cambia el color del texto si es necesario */
}

.tips-unselect{
    background-color: var(--light-background-background-1);
}

.dark .tips-unselect{
    background-color: var(--dark-background-background-1);
    color: var(--dark-text-text-1);
}

.add-multiple-tips-trash{
    display: flex;
    align-items: flex-start;
    padding-top: 3px;
}

.tips-selector-container{
    overflow: hidden;
    border-radius: 10px;
}
.add-multiple-tips .tips-selector-container{
    border: 1px solid #ccc;
    margin-bottom: 10px;
    background-color: var(--light-background-background-1);
}

.add-multiple-tips .tips-selector-container div{
    width: 100%;
    cursor: pointer;
    text-align: center;
}

.add-multiple-tips .selected {
    border-radius: 5px;
    background-color: var(--light-status-info);
    color: white;
    object-fit: cover;
}

.tips-buttons-text{
    font-weight: 600;
    color: var(--light-text-text-1);

}

.dark .add-multiple-tips .tips-selector-container{
    background-color: var(--dark-background-background-1);
}