.dark .MuiDayCalendar-monthContainer button {
    color: white;
}
.dark .MuiDateCalendar-root{
    color: white
}

.dark .MuiDateCalendar-root svg{
    color: white;
}

.dark .MuiDateCalendar-root .MuiDayCalendar-header span{
    color: white;
}