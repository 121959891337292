.container-uploads {
    display: flex;
    align-items: center;
}

.section-bold-upload-files {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
    background: var(--light-elements-elements-2);
    border: 2px dashed var(--light-elements-elements-5);
    border-radius: 10px;
    padding: 10px;
    overflow: auto;
}
.dark .section-bold-upload-files {
    background: var(--dark-elements-elements-2);
    border: 2px dashed var(--dark-elements-elements-2);
}

.background-button-white{
    background-color: white;
    border: 2px solid var(--light-elements-elements-8);
    display: block;
    max-width: 100%;
    padding: 8px 10px;
    border-radius: 10px;
    margin: 4px 0px;
    color: var(--dark-text-text-1);
}
.dark .background-button-white {
    border: 1px solid var(--dark-elements-elements-8);
}