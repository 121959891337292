.button-selector-filter-payment {
    width: 20%;
    border: none;
    border-radius: 10px;
    justify-content: center;
    font-size: var(--light-font-size-2);
}

.container-buttons-payment {
    background-color: var(--light-background-background-1);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
}

.dark .container-buttons-payment {
    background-color: var(--dark-background-background-1);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
}

.button-color-payment {
    background-color: var(--light-background-background-1);
    color: var(--light-text-text-1);
}
.dark .button-color-payment {
    background-color: var(--dark-background-background-1);
    color: var(--dark-text-text-1);
}

.selected-button-payment {
    background-color: var(--light-primary-blue-1);
    color: var(--light-text-text-3);
}

.dark .selected-button-payment{
    background-color: var(--dark-primary-blue-1);
    color: var(--dark-text-text-3);

}

.color-icon-payment {
    color: var(--light-text-text-1);

}
.dark .color-icon-payment {
    color: var(--dark-text-text-1);

}
.icon-size-payment{
    font-size: var(--light-font-size-3);

}
.dark .date-picker-payment-method svg{
    color: var(--dark-text-text-2);
}