.card-pay {
    height: 50px;
    border: 1px solid var(--light-neutral-gray-3);
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--light-neutral-white);
    margin-bottom: 10px;
}

.dark .card-pay {
    height: 50px;
    border: 1px solid var(--dark-neutral-gray-3);
    background-color: var(--dark-neutral-white);
}

.card-icons {
    justify-content: center;
    margin-right: 20px;
    display: flex;
    align-items: center;
    border: 1px solid var(--light-neutral-gray-3);
    width: 30px;
    height: 30px;
    border-radius: 25px;
    cursor: pointer;
}

.dark .card-icons {
    border: 1px solid var(--dark-neutral-gray-3);
}

.trash-iconPay {
    stroke: var(--light-secondary-black);
    stroke-width: 0.3px;
    cursor: pointer;
}

.dark .trash-iconPay {
    fill: var(--dark-secondary-black);
    stroke: var(--dark-secondary-black);
}

.search-icon, .close-icon {
    cursor: pointer;
    margin-left: 5px;
}

.tittleCardPay{
    font-size: 14px;
    font-weight: 600;
    color: var(--light-text-text-1);
}

.dark .tittleCardPay{
    color: var(--dark-text-text-1);
}

.numberCard {
    color: var(--light-text-text-1);
    font-size: 13px;
}

.dark .numberCard {
    color: var(--dark-text-text-1);
}
