.button-add{
    width: 120px;
}

.empty-text {
    font-size: var(--light-font-size-4);
    font-weight: var(--light-font-weights-source-sans-pro-0);
    color: var(--light-text-text-2);
}

.dark .empty-text {
    color: var(--dark-text-text-2);
}

.footer-centralmodal {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;

}