.card-container {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px 15px;
    background-color: var(--light-background-background-1);
    border: 1px solid var(--light-elements-border-panels-1);
}
.card-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--light-primary-blue-2);
    margin-right: 10px;
}
.card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.card-image-container i{
    color: var(--light-text-text-3);
    font-size: 20px;
}
.card-text-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.card-text-container  p {
    font-size: var(--light-font-size-2);
    font-weight: var(--light-font-weights-source-sans-pro-2);
    color: var(--light-text-text-1);
    margin: 0;
}
.card-button-arrow {
    display: flex;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: 1px solid var(--light-elements-border-panels-1);
    background-color: var(--light-background-background-1);
}
.card-button-arrow i{
    color: var(--light-text-text-1);
    font-size: 0.8rem;
    margin-top: 4px;
}
.card-button-container{
    align-self: flex-end;
    justify-content: space-between;
}
.dark .card-container {
    border-color: var(--dark-elements-elements-1);
    color: var(--dark-text-text-1);
    background-color: var(--dark-background-background-1);
}
.dark .card-image-container{
    background-color: var(--dark-primary-blue-2);
}
.dark .card-image-container i{
    color: var(--dark-text-text-3);
}
.dark .card-text-container  p{
    color: var(--dark-text-text-1)
}
.dark .card-button-arrow {
    border: 1px solid var(--dark-elements-border-panels-1);
    background-color: var(--dark-background-background-1);
}
.dark .card-button-arrow i{
    color: var(--dark-text-text-1);
}

