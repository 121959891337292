.cardSelect-container {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px 15px;
    background-color: var(--light-background-background-1);
    border: 1px solid var(--light-elements-border-panels-1);
}
.cardSelect-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--light-primary-blue-2);
    margin-right: 10px;
}
.cardSelect-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.cardSelect-image-container i{
    color: var(--light-text-text-3);
    font-size: 20px;
}
.cardSelect-text-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cardSelect-text-container  p {
    font-size: var(--light-font-size-2);
    font-weight: var(--light-font-weights-source-sans-pro-2);
    color: var(--light-text-text-1);
    margin: 0;
}
.cardSelect-button-arrow {
    display: flex;
    justify-content: center;
}
.cardSelect-button-arrow i{
    color: var(--light-text-text-1);
    font-size: 0.8rem;
    margin-top: 4px;
}
.selected-card {
    background-color: var(--light-primary-blue-1);
}
.selected-card-text {
    color: var(--light-text-text-5);
}
.dark .cardSelect-container {
    border-color: var(--dark-elements-elements-1);
    color: var(--dark-text-text-1);
    background-color: var(--dark-background-background-1);
}
.dark .cardSelect-image-container{
    background-color: var(--dark-primary-blue-2);
}
.dark .cardSelect-image-container i{
    color: var(--dark-text-text-3);
}
.dark .card-text-container  p{
    color: var(--dark-text-text-1)
}
.dark .cardSelect-button-arrow {
    background-color: var(--dark-background-background-1);
}
.dark .cardSelect-button-arrow i{
    color: var(--dark-text-text-1);
}
.dark .selected-card {
    background-color: var(--dark-primary-blue-1);
}
.dark .selected-card-text {
    color: var(--dark-text-text-5);
}
