.containerCredit {
    background-color: var(--light-background-background-1);
}

.dark .containerCredit {
    background-color: var(--dark-background-background-1);
}

.textCredit {
    color: var(--light-text-text-1)
}

.dark .textCredit {
    color: var(--dark-text-text-1)
}