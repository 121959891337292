.cardNotificationsCenter{
    height: 25vh; /* Asegura que ocupe todo el espacio disponible */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--light-background-background-1);
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 0 5px;
}
.dark .cardNotificationsCenter{
    background: var(--dark-background-background-1);
}
.cardNotificationsCenter .MuiFormControl-root{
    margin-bottom: 0px !important;
}
.messageBlue{
    color: var(--light-text-text-1);
    height: 4.5rem;
    margin: 10px 0;
}

.containerIcons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.5rem;
}
.messageBlue p {
    height: 4.5rem;              
    overflow: hidden;            
    display: -webkit-box;        
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;       
    line-clamp: 3;               
    text-overflow: ellipsis;     
    line-height: 1.5rem;         
}

.dark .messageBlue{
    color: var(--dark-text-text-1);
}
.textSwitch{
    color: var(--light-text-text-1);
}
.dark .textSwitch{
    color: var(--dark-text-text-1);
}

.icon-color-notifications {
    color: var(--light-primary-blue-1);
}

.dark .icon-color-notifications {
    color: var(--dark-primary-blue-1);
}

.bottomSection {
    margin-top: auto; /* Empuja este div hacia la parte inferior */
}

.container-card-to-switch{
    height: 100%;
    padding: 0px 0px 15px 0px;
}

.card-to-switch-message{
    height: 80%;
}

.card-to-switch-input{
    height: 10%;
}

.card-switch-not{
    padding-left: 20px !important;
}