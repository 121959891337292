.checkout-container-title {
    display: flex;
    align-items: center;
}

.checkout-total {
    color: var(--light-text-text-1);
}

.checkout-lineheight-0 {
    line-height: 0 !important;
}

.checkout-container-screen {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.checkout-header {
    width: 100%;
    height: 10vh;
    background-color: var(--light-background-background-1);
    display: flex;
    align-items: center;
    padding: 0 2vw;
    z-index: 5;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
}

.checkout-screen {
    display: flex;
    flex: 1; 
    width: 100%;
}

.checkout-container-left {
    width: 70%;
    height: 100%;
    padding: 5vh 5vw;
}

.checkout-container-right {
    width: 30%;
    height: 100%;
    position: relative;
    border-left: 1px solid var(--light-elements-border-panels-1);
    border-bottom: 1px solid var(--light-elements-border-panels-1);
}

.container-card-appointment {
    display: flex;
    flex-wrap: wrap;
    gap: 15px
}

.card-button-appointment {
    width: 200px;
    height: 80px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: var(--light-background-background-1);
    border: 1px solid var(--light-elements-border-panels-1);
}

.checkout-header-right {
    background-color: var(--light-background-background-1);
    padding: 15px 10px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
}
.checkout-container-box-cards {
    overflow-y: auto;
    padding: 5px 10px;
    max-height: 50vh;
    scrollbar-width: thin;
}

.checkout-container-box-cards-height{
    max-height: 60vh;
}


.checkout-container-footer {
    background-color: var(--light-background-background-1);
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid var(--light-neutral-gray-3);
    padding: 10px 15px;
    z-index: 3;
}

.dark .checkout-total {
    color: var(--dark-text-text-1);
}

.dark .checkout-container-footer{
    background-color: var(--dark-background-background-1);
    border-top: 1px solid var(--dark-neutral-gray-3);   
} 

.dark .checkout-header-right {
    background-color: var(--dark-background-background-1);
}
.dark .card-button-appointment {
    background-color: var(--dark-background-background-1);
    border: 1px solid var(--dark-elements-border-panels-1);
}

.dark .checkout-header {
    background-color: var(--dark-background-background-1);
}