.color-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
  
  .color-modal-content {
    background: var(--light-background-background-2);
    /* padding: 20px; */
    border-radius: 5px;
    position: relative;
    width: 350px;
  }
  .color-modal-header{
    background-color: var(--light-background-background-1);
    padding: 15px;
    border-radius: 5px 5px 0 0;
    display: flex;
    align-items: center;
    gap: 50px;
  }

  .color-modal-title{
    font-size: 1.3em;
    margin: 0;
  }
  .color-modal-body{
    padding: 20px;
  }
  .color-buttons{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .color-modal-section > h2{
    font-size: 16px;
  }